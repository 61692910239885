// ContentEpisodeNotes.jsx

import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { axiosPublic } from "../../../common/axiosPublic";
import { FadeLoader } from "react-spinners";
import Img from "../../../UI/Img";
import { ExternalLinkIcon, ChevronUpIcon, ChevronDownIcon, PencilAltIcon } from "@heroicons/react/outline";
import { ClipboardCopyIcon } from "@heroicons/react/solid";
import { useCopyToClipboard } from "usehooks-ts";
import EpisodeHeader from "./EpisodeHeader";
import RegisterCallToAction from "./RegisterCallToAction";
import RegisterCallToActionBottom from "./RegisterCallToActionBottom";
import { Helmet } from "react-helmet";
import SEO from "../../SEO";

const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = (seconds % 60).toString().padStart(2, "0");
  return `${h}:${m}:${s}`;
};

const ContentEpisodeNotes = () => {
  const { podcastId, episodeId } = useParams();
  const [episode, setEpisode] = useState(null);
  const [podcast, setPodcast] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  const [copiedText, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchEpisodeData = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const authHash = searchParams.get("auth");

        let url = `/repository/content/podcasts/${podcastId}/episodes/${episodeId}/notes`;
        if (authHash) {
          url += `?auth=${authHash}`;
        }

        const response = await axiosPublic.get(url);
        setEpisode(response.data.episode);
        setIsAuthenticated(response.data.is_authenticated || false);
      } catch (error) {
        console.error("Error fetching episode data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEpisodeData();
  }, [podcastId, episodeId, location.search]);

  const handleCopy = () => () => {
    copy(window.location.href)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };
  const renderChapterContent = (chapter, index) => (
    <div className="px-4 py-5 sm:px-6">
      <ul className="items-list">
        <p className="prose prose-sm mt-3 text-justify">{chapter.combined_summary}</p>
        {isAuthenticated && chapter.references && (
          <div className="mt-3">
            <h4 className="text-sm leading-6 font-medium text-secondary-600 mt-3 mb-2">References</h4>
            <ul className="list-disc pl-5">
              {chapter.references.map((reference, refIndex) => (
                <li key={refIndex} className="text-sm text-gray-700">
                  {reference}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex items-center">
          <h4 className="text-sm leading-6 font-medium text-start text-secondary-600 mt-5 mb-3">Notes</h4>
        </div>
        {chapter.combined_main_points.slice(0, isAuthenticated ? undefined : 10).map((point, pointIndex) => (
          <div className="px-6" key={pointIndex}>
            <li className="list-disc">
              <p className="prose prose-sm text-justify">{point}</p>
            </li>
          </div>
        ))}
        {!isAuthenticated && chapter.combined_main_points.length > 10 && <RegisterCallToAction />}
      </ul>
    </div>
  );
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-96">
        <FadeLoader color={"#fca311"} loading={isLoading} size={150} />
      </div>
    );
  }

  if (!episode) {
    return <div>Episode not found</div>;
  }

  return (
    <>
      <SEO
        title={`${episode.title} Episode Notes | ${episode.podcast_title} | PodScribe.IO`}
        description={episode.description.substring(0, 160)}
        type="website"
        url={`https://podscribe.io/content/podcasts/${podcastId}/episode/${episode.id}`}
        image={episode.feedImage}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://podscribe.io/content/podcasts/${podcastId}/episode/${episode.id}`,
            },
            headline: `${episode.title} Episode Notes | PodScribe.IO`,
            description: `Detailed notes and transcript for "${episode.title}" from ${episode.podcast_title}.`,
            image: episode.feedImage,
            author: {
              "@type": "Organization",
              name: "PodScribe.IO",
            },
            publisher: {
              "@type": "Organization",
              name: "PodScribe.IO",
              logo: {
                "@type": "ImageObject",
                url: "https://podscribe.io/og-image.jpg",
              },
            },
            datePublished: episode.publishDate,
            dateModified: new Date().toISOString(),
          })}
        </script>
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <EpisodeHeader episode={episode} />
        <div className="flex flex-col items-center justify-between w-full">
          <div className="flex flex-col w-full lg:w-4/5 sm:w-4/5">
            <div className="flex flex-row">
              <button onClick={handleCopy()} title="Copy URL">
                <ClipboardCopyIcon className="h-5 w-5 text-secondary-600" aria-hidden="true" />
              </button>
              {isCopied && <span className="ml-2 text-sm text-secondary-600">Copied!</span>}
            </div>
            <h4 className="text-lg leading-6 font-semibold text-primary-800 mt-5 text-left">Introduction</h4>
            <div className="text-sm items-center justify-between flex flex-row w-full">
              <article className="prose prose-sm pt-5 pb-5 text-justify w-full">
                <p>{episode.summary.episode_summary.summary}</p>
              </article>
            </div>
          </div>

          <div className="flex flex-col w-full lg:w-4/5 sm:w-4/5">
            <h4 className="text-lg leading-6 font-semibold text-primary-800 mt-5 text-left">Quick Takeaways</h4>
            <ul className="list-none">
              {episode.summary.chapters.map((chapter, index) => (
                <li key={index} className="">
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex">
                      <PencilAltIcon
                        className="h-5 w-5 text-primary-600"
                        aria-hidden="true"
                        aria-label="Quick Takeaways"
                      />
                    </div>
                    <div className="flex w-full ml-3">
                      <p className="prose prose-sm text-justify mt-5">{chapter.combined_key_takeaway}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col space-y-3 mt-5 pb-12 w-full lg:w-4/5 sm:w-4/5">
            <h4 className="text-lg leading-6 font-semibold text-primary-800 mt-5 text-left">Deepdive</h4>
            {episode.summary.chapters.map((chapter, index) => (
              <React.Fragment key={index}>
                {!isAuthenticated && index === 4 && <RegisterCallToAction />}
                <div className="bg-white py-1 w-full">
                  <div className="-ml-4 -mt-4 flex items-center justify-between sm:flex-nowrap">
                    <div className="sm:ml-4 mt-2">
                      <h5
                        className={`text-lg leading-6 px-6 ${
                          !isAuthenticated && index >= 4 ? "text-gray-400" : "text-primary-600"
                        }`}>
                        {chapter.title}
                      </h5>
                      {chapter.start_time !== undefined && chapter.end_time !== undefined && (
                        <p className="text-sm text-gray-500 px-6">
                          {formatTime(chapter.start_time)} - {formatTime(chapter.end_time)}
                        </p>
                      )}
                    </div>
                    <div className="sm:ml-4 mt-4 flex-shrink-0">
                      <button
                        type="button"
                        className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ${
                          !isAuthenticated && index >= 4 ? "text-gray-400 cursor-not-allowed" : "text-white"
                        }`}
                        onClick={() =>
                          isAuthenticated || index < 4 ? setOpenIndex(openIndex === index ? null : index) : null
                        }
                        disabled={!isAuthenticated && index >= 4}>
                        {openIndex === index ? (
                          <ChevronUpIcon
                            className={`h-5 w-5 ${
                              !isAuthenticated && index >= 4 ? "text-gray-400" : "text-primary-600"
                            }`}
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className={`h-5 w-5 ${
                              !isAuthenticated && index >= 4 ? "text-gray-400" : "text-primary-600"
                            }`}
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  {openIndex === index && renderChapterContent(chapter, index)}
                </div>
              </React.Fragment>
            ))}
            {!isAuthenticated && <RegisterCallToActionBottom />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentEpisodeNotes;
