/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EmojiHappyIcon, EmojiSadIcon } from "@heroicons/react/solid";
import { axiosPrivate } from "../common/axiosPrivate";
import toast from "react-hot-toast";

export default function FeedbackModal(props) {
  const cancelButtonRef = useRef(null);
  let title = "Tweet us your feedback";
  let HandleOnClose = props.HandleOnClose;
  let HandleOnAction = props.HandleOnAction;
  let show = props.show;
  const [selectedEmoji, setSelectedEmoji] = useState("not_selected");
  const [feedbackText, setFeedbackText] = useState("");

  const handleEmojiClick = (emoji) => {
    setSelectedEmoji(emoji);
  };

  const handleTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  useEffect(() => {
    console.log("selectedEmoji", selectedEmoji);
  }, [selectedEmoji]);

  const handleFeedback = async () => {
    try {
      const response = await axiosPrivate.post("/users/feedback", {
        emoji: selectedEmoji,
        feedback: feedbackText,
      });

      console.log("Feedback sent successfully", response);
      // Clear the states
      setSelectedEmoji("not_selected");
      setFeedbackText("");

      toast.success("Feedback sent successfully. Thanks for your feedback!");
      HandleOnClose();
    } catch (error) {
      console.error("Error sending feedback", error);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={HandleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-primary-600">
                      {title}
                    </Dialog.Title>
                    <div className="mt-5 mb-3 mx-auto flex h-12 w-12 items-center justify-center">
                      <div className="flex mb-4">
                        <button onClick={() => handleEmojiClick("happy")}>
                          <EmojiHappyIcon
                            className={
                              selectedEmoji === "happy"
                                ? "w-12 h-12 text-green-500 mr-2 border-2 border-primary-500"
                                : "w-12 h-12 text-green-500 mr-2"
                            }
                          />
                        </button>
                        <button onClick={() => handleEmojiClick("sad")}>
                          <EmojiSadIcon
                            className={
                              selectedEmoji === "sad"
                                ? "w-12 h-12 text-red-500 mr-2 border-2 border-primary-500"
                                : "w-12 h-12 text-red-500 mr-2"
                            }
                          />
                        </button>
                      </div>
                    </div>
                    <p className="mb-4 text-neutral-800 text-sm">
                      We value your feedback and want to hear about your experience. Please take a moment to share your
                      thoughts, feelings, and ideas, feature requests, or anything else you'd like to share.
                    </p>
                    <textarea
                      className="w-full h-32 p-2 border border-primary-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-primary-500"
                      placeholder="Tell us why? (257 characters left)"
                      maxLength={257}
                      value={feedbackText}
                      onChange={handleTextChange}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:col-start-2"
                    onClick={() => handleFeedback()}>
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:col-start-1 sm:mt-0"
                    onClick={() => HandleOnClose()}
                    ref={cancelButtonRef}>
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
