import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GhostContentAPI from "@tryghost/content-api";
import { FadeLoader } from "react-spinners";
import { Container } from "../Container";
import { Header } from "../Header";
import { Footer } from "../Footer";
import SEO from "../../SEO";

const api = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_API_URL,
  key: process.env.REACT_APP_GHOST_CONTENT_API_KEY,
  version: "v5.0",
});

export function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.posts
      .browse({ limit: 5 })
      .then((response) => {
        setPosts(response);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <main>
          <Container className="flex justify-center items-center min-h-screen">
            <FadeLoader color="#36D7B7" />
          </Container>
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <SEO title="Blog | PodScribe.IO" description="Read the latest articles and updates on PodScribe.IO." />
      <Header />
      <main>
        <Container className="py-16">
          <h2 className="text-4xl font-bold text-center text-primary-900 mb-8">Blog</h2>
          {loading ? (
            <p>Loading blog posts...</p>
          ) : (
            <div className="grid grid-cols-1 gap-6 mt-12 sm:grid-cols-2 lg:grid-cols-3">
              {posts.map((post) => (
                <div
                  key={post.id}
                  className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
                  {post.feature_image && (
                    <img src={post.feature_image} alt={post.title} className="w-full h-32 object-cover" />
                  )}
                  <div className="p-6 flex flex-col justify-between">
                    <h3 className="text-xl font-semibold text-primary-600 mb-2 flex-grow">
                      <Link to={`/blog/${post.slug}`} className="hover:underline">
                        {post.title}
                      </Link>
                    </h3>
                    <p className="text-gray-600 line-clamp-3">{post.excerpt}</p>
                    <Link
                      to={`/blog/${post.slug}`}
                      className="mt-4 inline-flex items-center text-primary-600 hover:text-primary-800 font-medium">
                      Read More
                      <svg className="ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M12.293 9l-4.647-4.646a.5.5 0 01.707-.708l5 5a.5.5 0 010 .708l-5 5a.5.5 0 11-.707-.708L12.293 11H5.5a.5.5 0 010-1h6.793z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Container>
      </main>
      <Footer />
    </>
  );
}
