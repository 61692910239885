import { MicrophoneIcon } from "@heroicons/react/outline";
import { ReactComponent as MyLogo } from "../assets/logo_2.svg";

export default function PodcastIcon(props) {
  // iff className is passed in, use it, otherwise use the default
  const className = props.className
    ? props.className
    : "h-10 w-10 fill-primary";

  return (
    <div className="flex items-center justify-center">
      <MyLogo className={className} />
    </div>
  );
}
