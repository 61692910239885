import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ChevronRightIcon, DownloadIcon, LightningBoltIcon } from "@heroicons/react/solid";
import Img from "../../../UI/Img";

/**
 * EpisodeList component displays a list of podcast episodes.
 *
 * @param {Object} props
 * @param {Array} props.episodes - An array of episode objects
 * @param {string} props.podcastId - The ID of the podcast
 */
const EpisodeList = ({ episodes, podcastId }) => {
  return (
    <div className="container mx-auto px-4">
      {episodes.map((episode) => (
        <div
          key={episode.id}
          className="bg-white shadow-md rounded-lg overflow-hidden mb-6 transition-transform hover:scale-102">
          <div className="md:flex">
            <div className="md:flex-shrink-0">
              <Img src={episode.feedImage} alt={episode.title} className="h-48 w-full object-cover md:w-48" />
            </div>
            <div className="p-6">
              <Link to={`/content/podcasts/${podcastId}/episode/${episode.id}`} className="block group">
                <h3 className="text-xl font-semibold text-primary-600 group-hover:text-secondary-600 mb-2 flex items-center">
                  {episode.title.length > 50 ? episode.title.substring(0, 50) + "..." : episode.title}
                  <ChevronRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                </h3>
              </Link>
              <p className="text-gray-600 mb-4 line-clamp-2">{episode.description}</p>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-500">
                  <time
                    dateTime={new Date(episode.datePublished * 1000).toISOString()}
                    className="flex-shrink-0 whitespace-nowrap text-sm text-neutral-500">
                    {format(new Date(episode.datePublished * 1000), "MM/dd/yyyy")}
                  </time>
                </span>
                <div className="flex items-center space-x-3">
                  <Link to={episode.enclosureUrl} target="_blank" className="text-primary-600 hover:text-primary-800">
                    <DownloadIcon className="h-5 w-5" aria-hidden="true" />
                  </Link>
                  {episode.is_transcribed && (
                    <span className="inline-flex items-center rounded-full px-3 py-1 text-xs font-medium bg-secondary-100 text-secondary-800">
                      <LightningBoltIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                      Podscribed
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EpisodeList;
