import React, { createContext, useState } from "react";

const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [UIState, setUIState] = useState({
    inboxSidebarOpen: false,
    feedbackModalOpen: false,
    inboxConfig: {
      sort: {
        key: "datePublished",
        order: "desc"
      },
      group: {
        isGrouped: false,
        groupKey: "podcast_title"
      }
    }
  });
  console.log("UIState", UIState);
  return (
    <UIContext.Provider value={{ UIState, setUIState }}>
      {children}
    </UIContext.Provider>
  );
};

export default UIContext;
