import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function FileDropzone({ file, setFile, maxSize = 10 * 1024 * 1024 }) {
  const onDrop = useCallback((acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    if (uploadedFile && uploadedFile.type === "audio/mpeg") {
      if (uploadedFile.size <= maxSize) {
        setFile(uploadedFile);
      } else {
        alert(`File size exceeds the limit of ${maxSize / (1024 * 1024)}MB.`);
      }
    } else {
      alert("Please upload an MP3 file.");
    }
  }, [setFile, maxSize]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "audio/mpeg": [".mp3"] },
    multiple: false,
    maxSize: maxSize,
  });

  return (
    <div
      {...getRootProps()}
      className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 border-dashed rounded-md"
    >
      <div className="space-y-1 text-center">
        {file ? (
          <div>
            <svg
              className="mx-auto h-12 w-12 text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <p className="mt-2 text-sm text-neutral-600">{file.name}</p>
          </div>
        ) : (
          <>
            <svg
              className="mx-auto h-12 w-12 text-neutral-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-neutral-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
              >
                <span>Upload a file</span>
                <input {...getInputProps()} />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-neutral-500">MP3 up to {maxSize / (1024 * 1024)}MB</p>
          </>
        )}
      </div>
    </div>
  );
}
