import { HashLink } from "react-router-hash-link";

export function NavLink({ href, children }) {
  return (
    <HashLink
      to={href}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </HashLink>
  );
}
