import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { getTracker } from "../common/tracker";

const Logout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      setAuth({
        token: null,
        loading: false,
      });
      localStorage.removeItem("user");
      localStorage.removeItem("session");
      // Remove some additional states as required

      const tracker = getTracker();
      tracker.stop(); // Stops the tracker completely

      navigate("/login", { replace: true });
      navigate("/", { replace: true });
    };

    handleLogout();
  }, [setAuth, navigate]);

  return (
    <div className="flex justify-center items-center h-screen">
      <FadeLoader color={"#fca311"} size={150} />
    </div>
  );
};

export default Logout;
