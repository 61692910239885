import react from "react";
import { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon, PencilAltIcon, PlayIcon } from "@heroicons/react/outline";
import InfoMsgAction from "../../UI/InfoMsgAction";
import { useCopyToClipboard } from "usehooks-ts";
import { ClipboardCopyIcon } from "@heroicons/react/solid";

const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = (seconds % 60).toString().padStart(2, "0");
  return `${h}:${m}:${s}`;
};

export const Summary = (props) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [copiedText, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = (text) => () => {
    copy(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };

  const getMarkdownSummary = (props) => {
    let markdown = `# ${props.selectedEpisodeState.episode?.title}\n\n`;
    markdown += `${props.selectedEpisodeState.episode.summary?.episode_summary?.summary}\n\n`;
    markdown += `## Quick Takeaways\n\n`;
    props.selectedEpisodeState.episode.summary.chapters.forEach((chapter) => {
      markdown += `- ${chapter.combined_key_takeaway}\n`;
    });
    markdown += `\n## Deepdive\n\n`;
    props.selectedEpisodeState.episode.summary.chapters.forEach((chapter) => {
      markdown += `### ${chapter.title}\n\n`;
      markdown += `${chapter.combined_summary}\n\n`;
      markdown += `#### Notes\n\n`;
      chapter.combined_main_points.forEach((point) => {
        markdown += `- ${point}\n`;
      });
    });
    return markdown;
  };

  return (
    <div className="flex flex-col items-center justify-between m-5 w-full">
      {props.selectedEpisodeState.episode.summary?.chapters ? (
        <div className="flex flex-col justify-between items-center lg:w-4/5 sm:w-4/5 w-full p-5">
          <div className="flex flex-col w-full">
            <div className="flex flex-row">
              <button onClick={handleCopy(getMarkdownSummary(props))} title="Copy Markdown">
                <ClipboardCopyIcon className="h-5 w-5 text-secondary-600" aria-hidden="true" />
              </button>
              {isCopied && <span className="ml-2 text-sm text-secondary-600">Copied!</span>}
            </div>
            <h4 className="text-lg leading-6 font-semibold text-primary-800 mt-5 text-left">Introduction</h4>
            <div className="text-sm items-center justify-between flex flex-row w-full">
              <article className="prose prose-sm pt-5 pb-5 text-justify w-full">
                <p className="">{props.selectedEpisodeState.episode.summary?.episode_summary?.summary}</p>
              </article>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <h4 className="text-lg leading-6 font-semibold text-primary-800 mt-5 text-left">Quick Takeaways</h4>

            <ul className="list-none">
              {props.selectedEpisodeState.episode.summary.chapters.map((chapter, index) => (
                <li key={index} className="">
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex">
                      <PencilAltIcon
                        className="h-5 w-5 text-primary-600"
                        aria-hidden="true"
                        aria-label="Quick Takeaways"
                      />
                    </div>
                    <div className="flex w-full ml-3">
                      <p className="prose prose-sm text-justify mt-5">{chapter.combined_key_takeaway}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col space-y-3 mt-5 pb-12 w-full">
            <h4 className="text-lg leading-6 font-semibold text-primary-800 mt-5 text-left">Deepdive</h4>
            {props.selectedEpisodeState.episode.summary.chapters.map((chapter, index) => (
              <div key={index} className=" bg-white py-1 w-full">
                <div className="-ml-4 -mt-4 flex items-center justify-between sm:flex-nowrap">
                  <div className="sm:ml-4 mt-2 flex items-center">
                    <PlayIcon
                      className="h-5 w-5 text-primary-600 hover:text-primary-700 cursor-pointer mr-2"
                      aria-hidden="true"
                      title="Play"
                      onClick={() => props.SelectSegmentHandler(chapter.start_time)}
                    />
                    <div>
                      <h5 className="text-lg leading-6 text-primary-600">{chapter.title}</h5>
                      {chapter.start_time !== undefined && chapter.end_time !== undefined && (
                        <p className="text-sm text-gray-500">
                          {formatTime(chapter.start_time)} - {formatTime(chapter.end_time)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:ml-4 mt-4 flex-shrink-0">
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white "
                      onClick={() => setOpenIndex(openIndex === index ? null : index)}>
                      {openIndex === index ? (
                        <ChevronUpIcon className="h-5 w-5 text-primary-600" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon className="h-5 w-5 text-primary-600" aria-hidden="true" />
                      )}
                    </button>
                  </div>
                </div>
                {openIndex === index && (
                  <div className="px-4 py-5 sm:px-6">
                    <ul className="items-list">
                      <div className="flex items-center"></div>
                      <p className="prose prose-sm mt-3 text-justify">{chapter.combined_summary}</p>
                      {chapter.references && (
                        <div className="mt-3">
                          <h4 className="text-sm leading-6 font-medium text-secondary-600 mt-3 mb-2">References</h4>
                          <ul className="list-disc pl-5">
                            {chapter.references.map((reference, refIndex) => (
                              <li key={refIndex} className="text-sm text-gray-700">
                                {reference}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div className="flex items-center">
                        <h4 className="text-sm leading-6 font-medium text-start text-secondary-600 mt-5 mb-3">Notes</h4>
                      </div>
                      {chapter.combined_main_points.map((point, pointIndex) => (
                        <div className="px-6" key={pointIndex}>
                          <li className="list-disc">
                            <p className="prose prose-sm text-justify ">{point}</p>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <InfoMsgAction
            msg="Podcast summary is not available"
            action="Transcribe"
            onClick={props.HandleTranscribeEpisode}
          />
        </div>
      )}
    </div>
  );
};

export default Summary;
