import classNames from "classnames";

export function Container({ className, ...props }) {
  const containerClass = classNames(
    "mx-auto",
    "max-w-7xl",
    "px-4",
    "sm:px-6",
    "lg:px-8",
    className,
  );

  return <div className={containerClass} {...props} />;
}
