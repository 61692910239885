import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import decode from "jwt-decode";
import axios from "axios";
import { axiosPrivate } from "../common/axiosPrivate";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: null,
    user: null,
    loading: true,
  });
  let navigate = useNavigate();

  const refreshUserData = async () => {
    try {
      const response = await axiosPrivate.get("/users/me");
      setAuth((prevAuth) => ({ ...prevAuth, user: response.data }));
      localStorage.setItem("user", JSON.stringify(response.data));
      console.log("User data refreshed: ", response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.");
        localStorage.removeItem("session");
        localStorage.removeItem("user");
        setAuth((prevAuth) => ({ ...prevAuth, token: null, user: null }));
        navigate("/login");
      }
      console.log(error);
    }
  };

  // read token from local storage and validate if it is expired

  useEffect(() => {
    const session = localStorage.getItem("session");
    const user_store = localStorage.getItem("user");
    const token = JSON.parse(session)?.access_token;
    const user = JSON.parse(user_store);
    console.log("Loaded session info", JSON.parse(session));
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        console.log("Token expired.");
      }
      setAuth({
        token: token,
        user: user,
        loading: false,
      });
      console.log("Token loaded from local storage.");
      // verify subscription status
      refreshUserData();
      // verify user data
    } else {
      setAuth({
        token: null,
        user: null,
        loading: false,
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, refreshUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
