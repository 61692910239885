import { Footer } from "./Footer";
import { Header } from "./Header";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import { useEffect } from "react";
import SEO from "../SEO";
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/outline";

export default function Refund() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("refund.md")
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <>
      <SEO title="PodScribe.IO |Refund Policy" description="Refund Policy for PodScribe.IO" />
      <Header />
      <main>
        <div className="bg-white px-6 py-32 lg:px-8">
          <div className="flex flex-col items-center justify-between">
            <ReactMarkdown className="prose prose-sm">{content}</ReactMarkdown>

            <p></p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
