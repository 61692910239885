import { Footer } from "../Footer";
import { Header } from "../Header";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import SEO from "../../SEO";

export default function ContentLayout(props) {
  return (
    <>
      <SEO title="PodScribe.IO | The Best Podcast Episode Notes" />
      <Header />
      <main>
        <div className="bg-white px-6 py-6 lg:px-8">
          <div className="flex flex-col items-center justify-between">
            <div className="w-full max-w-4xl">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
