import React from "react";

export default function LinkInput({ link, setLink }) {
  return (
    <div>
      <label htmlFor="link" className="block text-sm font-medium text-neutral-700">
        Paste your link here
      </label>
      <div className="mt-1">
        <input
          type="text"
          name="link"
          id="link"
          className="block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
          placeholder="https://example.com/podcast-episode"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </div>
    </div>
  );
}
