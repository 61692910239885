// RegisterCallToAction.jsx

import React from "react";
import { Link } from "react-router-dom";

const RegisterCallToAction = () => {
  return (
    <div className="bg-primary-100 border-l-4 border-primary-500 p-4 my-4 rounded-r-lg shadow-md">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-primary-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-primary-800">Unlock Full Access to Episode Notes</h3>
          <div className="mt-2 text-sm text-primary-700">
            <p>Register now to view the complete episode notes, including detailed summaries and key takeaways.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <Link
                to="/register"
                className="bg-primary-500 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Register & Unlock the Insights Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterCallToAction;
