import { toast } from "react-hot-toast";
import { Navigate, Outlet, Route, useOutletContext } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuthentication = ({ component: Component, ...rest }) => {
  const { auth, refreshUserData } = useAuth();
  const [SetShowSecondaryColumn] = useOutletContext();
  const { loading } = auth;
  if (loading == true) {
    return <div></div>;
  }
  return auth.token ? (
    <Outlet context={[SetShowSecondaryColumn]} />
  ) : (
    <Navigate to="/login" />
  );
};

export default RequireAuthentication;
