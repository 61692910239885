import React from 'react';
import { FadeLoader } from 'react-spinners';

export default function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center h-[300px]">
      <FadeLoader color="#fca311" />
    </div>
  );
}
