import React from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AppShell from "./AppShell";

const Layout = () => {
  const [ShowSecondaryColumn, SetShowSecondaryColumn] = useState(false);

  return (
    <>
      <Toaster position="top-right" />
      <AppShell ShowSecondaryColumn={ShowSecondaryColumn}>
        <Outlet context={[SetShowSecondaryColumn]} />
      </AppShell>
    </>
  );
};

export default Layout;
