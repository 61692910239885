import React from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import Pricing from "./Pricing";
import PaddleScript from "./PaddleScript";
import { Helmet } from "react-helmet";
import SEO from "../SEO";
export default function Subscription(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const { auth } = useAuth();
  const userEmail = auth.user.email;

  return (
    <>
      <SEO title="PodScribe.IO | Subscription" />
      <div className=" bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
        {/* Page Header */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-primary-600">Subscription</h3>
            <p className="text-sm leading-6 text-neutral-500">Manage subscription packages</p>
          </div>
        </div>
      </div>
      {/* User settings Form */}
      <div className="items-center justify-between flex flex-col sm:m-5">
        <div className="m-5 p-5 min-w-full">
          <Pricing></Pricing>
          {/* User settings Form */}
        </div>
      </div>
    </>
  );
}
