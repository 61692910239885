import { useEffect, useReducer, useRef } from "react";
import CardPagination from "../../UI/Pagination";
import { ArrowRightIcon, PlayIcon } from "@heroicons/react/solid";
import { Highlight } from "../../common/utils";

const initialState = {
  feedData: [],
  filteredFeedData: [],
  totalItems: 0,
  startIndex: 0,
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
  searchTerm: "",
  show_search_results: false,
  searchData: [],
  scrollToParagraphIndex: null,
};

const paginationReducer = (currntState, action) => {
  if (action.type === "INIT") {
    return {
      ...currntState,
      feedData: action.feedData,
      totalItems: action.feedData.length,
      totalPages: action.totalPages,
    };
  }
  if (action.type === "PAGINATION") {
    return {
      ...currntState,
      show_search_results: false,
      currentPage: action.currentPage,
      startIndex: (action.currentPage - 1) * currntState.pageSize,
      filteredFeedData: currntState.feedData.slice(
        (action.currentPage - 1) * currntState.pageSize,
        action.currentPage * currntState.pageSize
      ),
      scrollToParagraphIndex: action.scrollToParagraphIndex,
    };
  }
  if (action.type === "SEARCH") {
    const allParagraphs = currntState.feedData.map((item, paragraphIndex) => ({
      text: item.sentences.map((sentence) => sentence.text).join(" "),
      episodeId: item.episodeId,
      paragraphIndex: paragraphIndex,
      start: item.start,
      end: item.end,
    }));

    const matchingText = allParagraphs
      .filter((item) => item.text.toLowerCase().includes(action.searchTerm.toLowerCase()))
      .map((item) => {
        const origin = {
          episodeId: item.episodeId,
          paragraphIndex: item.paragraphIndex,
          start: item.start,
          end: item.end,
          page: Math.max(1, Math.ceil((item.paragraphIndex + 1) / currntState.pageSize)),
          pageRelativeParagraphIndex: item.paragraphIndex % currntState.pageSize,
        };
        return { text: item.text, origin };
      });

    return {
      ...currntState,
      show_search_results: true,
      searchData: matchingText,
    };
  }
};

export default function Transcript(props) {
  const [paginationState, dispatch] = useReducer(paginationReducer, initialState);
  const wrapperRef = useRef(null);

  const onNext = () => {
    console.log(" TotalPages:", paginationState.totalPages, "CurrentPage:", paginationState.currentPage);
    if (paginationState.currentPage < paginationState.totalPages) {
      dispatch({
        type: "PAGINATION",
        currentPage: paginationState.currentPage + 1,
      });
      wrapperRef.current.scrollIntoView();
    }
  };

  const onPrevious = () => {
    if (paginationState.currentPage > 1) {
      dispatch({
        type: "PAGINATION",
        currentPage: paginationState.currentPage - 1,
      });
      wrapperRef.current.scrollIntoView();
    }
  };

  const onPageChange = (page, paragraphIndex) => {
    console.log("page", page, "paragraphIndex", paragraphIndex);
    dispatch({
      type: "PAGINATION",
      currentPage: page,
      scrollToParagraphIndex: paragraphIndex,
    });
  };

  // Handle change of props.searchTerm
  useEffect(() => {
    if (props.searchTerm !== "") {
      dispatch({ type: "SEARCH", searchTerm: props.searchTerm });
      console.log("searchTerm", props.searchTerm);
    } else {
      // reset to initial state
      dispatch({
        type: "PAGINATION",
        currentPage: paginationState.currentPage,
      });
      console.log("paginationState after search box empty", paginationState.totalPages, paginationState.currentPage);
    }
  }, [props.searchTerm]);

  useEffect(() => {
    if (props.data !== null && props.data.length > 0) {
      const TotalItems = props.data.length;
      const TotalPages = Math.ceil(TotalItems / initialState.pageSize);
      dispatch({ type: "INIT", feedData: props.data, totalPages: TotalPages });
      dispatch({ type: "PAGINATION", currentPage: 1 });
    }
  }, [props.data]);

  useEffect(() => {
    const paragraphElement = document.getElementById(`paragraph-${paginationState.scrollToParagraphIndex}`);
    console.log("scrollToParagraphIndex", paginationState.scrollToParagraphIndex);
    if (paragraphElement) {
      paragraphElement.scrollIntoView();
    }
  }, [paginationState.scrollToParagraphIndex]);

  return (
    <>
      <div className="flex flex-col items-center justify-between m-3">
        {paginationState.show_search_results && (
          <div className="min-h-0 flex flex-col items-center justify-between overflow-y-auto">
            <h3 className="text-lg leading-6 font-medium text-center text-primary-600">
              Search Results of matched paragraphs
            </h3>
            {paginationState.searchData.map((searchItem, searchItemIdx) => (
              <div
                key={searchItem.id || searchItemIdx}
                className="mt-2 text-sm p-3 items-center justify-between flex flex-col">
                <div className="flex flex-row space-x-3">
                  <p className="text-primary-600 text-sm">
                    {Math.floor(searchItem.origin.start / 60)}:
                    {(searchItem.origin.start % 60).toFixed(0).padStart(2, "0")} -{" "}
                    {Math.floor(searchItem.origin.end / 60)}:{(searchItem.origin.end % 60).toFixed(0).padStart(2, "0")}
                  </p>
                  <ArrowRightIcon
                    className="h-5 w-5 text-primary-600 hover:text-primary-700 cursor-pointer clickable hover:scale-110 transition duration-150 ease-in-out active:scale-100"
                    onClick={() => onPageChange(searchItem.origin.page, searchItem.origin.pageRelativeParagraphIndex)}
                  />
                </div>
                <article className="prose prose-sm text-justify border-t pt-5 pb-5 w-full">
                  <Highlight
                    text={searchItem.text}
                    highlight={props.searchTerm}
                    context={100}
                    className="text-sm font-medium"
                  />
                </article>
              </div>
            ))}
          </div>
        )}

        {!paginationState.show_search_results && (
          <nav aria-label="feed list" className="min-h-0 p-1 mb-1 overflow-y-auto">
            <div ref={wrapperRef}></div>
            <ul role="list">
              {paginationState.filteredFeedData.map((activityItem, activityItemIdx) => (
                <li key={activityItemIdx} className="flex flex-col">
                  <div className="relative flex flex-row items-center pb-4 w-full">
                    {activityItemIdx !== paginationState.filteredFeedData.length - 1 ? <></> : null}
                    <div className="flex w-full">
                      {activityItem.sentences ? (
                        <div className="flex flex-col items-center justify-between w-full">
                          <div id={`paragraph-${activityItemIdx}`} className="flex flex-row space-x-3">
                            <p className="text-primary-600 text-sm">
                              {Math.floor(activityItem.start / 60)}:
                              {(activityItem.start % 60).toFixed(0).padStart(2, "0")} -{" "}
                              {Math.floor(activityItem.end / 60)}:{(activityItem.end % 60).toFixed(0).padStart(2, "0")}
                            </p>
                            <PlayIcon
                              className="h-5 w-5 text-primary-600 hover:text-primary-700 cursor-pointer clickable hover:scale-110 transition duration-150 ease-in-out active:scale-100"
                              aria-hidden="true"
                              title="Play"
                              onClick={() => props.SelectSegmentHandler(activityItem.start)}
                            />
                          </div>
                          <div className="text-sm items-center justify-between flex flex-row w-full">
                            <article className="prose prose-sm text-justify border-t pt-5 pb-5 w-full">
                              {activityItem.sentences.map((item, itemIdx) => (
                                <p key={itemIdx}>{item.text}</p>
                              ))}
                            </article>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      {!paginationState.show_search_results && (
        <CardPagination
          TotalResults={paginationState.totalItems}
          ItemsPerPage={paginationState.pageSize}
          pageSize={paginationState.pageSize}
          CurrentPage={paginationState.currentPage}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      )}
    </>
  );
}
