import react from "react";
import { Fragment, useState, useRef, useMemo, useEffect, useCallback } from "react";
import FolderSelectionModal from "./FolderSelectionModal";
import { Dialog, Transition } from "@headlessui/react";
import CommandPalette from "./CommandPalette";
import moment, { duration } from "moment";
import InboxContent from "./InboxContent";
import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import "./player.css";
import { PencilAltIcon } from "@heroicons/react/solid";
import TimestampNoteModal from "./TimestampNoteModal";
import { Waveform } from "../../UI/WaveForm";
import parse from "html-react-parser";
import {
  DocumentTextIcon,
  TrashIcon,
  ExternalLinkIcon,
  ShareIcon,
  BookmarkIcon,
  FastForwardIcon,
  ClockIcon,
  BookOpenIcon,
  FlagIcon,
} from "@heroicons/react/solid";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import useUIState from "../../hooks/useUIState";
import { useCopyToClipboard } from "usehooks-ts";
import ActionModalPleaseSubscribeToUse from "../../UI/ActionModalPleaseSubscribeToUse";
import { ExclamationIcon } from "@heroicons/react/solid";
import ExportModal from "./ExportModal";
import ShareEpisodeModal from "./ShareEpisodeModal";
import {
  ArchiveIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsHorizontalIcon,
  FolderDownloadIcon,
  PencilIcon,
  ReplyIcon,
  SearchIcon,
  UserAddIcon,
  LightningBoltIcon,
  ChatAltIcon,
  MenuAlt1Icon,
  GiftIcon,
  SearchCircleIcon,
  ArrowUpIcon,
  PlusIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import { BarLoader, BeatLoader } from "react-spinners";
import Img from "../../UI/Img";
import PodscribeActionModal from "./PodscribeActionModal";
import { remove_html_tags } from "../../common/utils";
import { Link } from "react-router-dom";
import useLibraryData from "../../hooks/useLibraryData";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const InboxMessage = (props) => {
  const [showEditor, setShowEditor] = useState(true);
  const [showTranscribe, setShowTranscribe] = useState(true);
  const [playAudio, setPlayAudio] = useState(false);
  const [toggleEpisodeDescription, setToggleEpisodeDescription] = useState(true);
  const [TranscribeInProgress, setTranscribeInProgress] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showPodscribeModal, setShowPodscribeModal] = useState(false);
  const { auth } = useAuth();
  const [copiedText, copy] = useCopyToClipboard();
  const [shareUrl, setShareUrl] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "p") {
        event.preventDefault();
        setIsCommandPaletteOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isTimestampModalOpen, setIsTimestampModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);

  useEffect(() => {
    if ("mediaSession" in navigator && props.episode) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: props.episode.title,
        artist: props.episode.podcast_title,
        album: props.episode.podcast_title,
        artwork: [
          {
            src: props.episode.feedImage,
            sizes: "512x512",
            type: "image/jpeg",
          },
        ],
      });

      // Add media session action handlers
      navigator.mediaSession.setActionHandler("play", () => setPlayAudio(true));
      navigator.mediaSession.setActionHandler("pause", () => setPlayAudio(false));
      navigator.mediaSession.setActionHandler("seekbackward", (details) => {
        const skipTime = details.seekOffset || 10;
        const currentTime = playerRef.current.getCurrentTime();
        const newTime = Math.max(currentTime - skipTime, 0);
        playerRef.current.seekTo(newTime);
        // Update position state after seeking
        navigator.mediaSession.setPositionState({
          duration: playerRef.current.getDuration(),
          playbackRate: 1,
          position: newTime,
        });
      });
      navigator.mediaSession.setActionHandler("seekforward", (details) => {
        const skipTime = details.seekOffset || 10;
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        const newTime = Math.min(currentTime + skipTime, duration);
        playerRef.current.seekTo(newTime);
        // Update position state after seeking
        navigator.mediaSession.setPositionState({
          duration: duration,
          playbackRate: 1,
          position: newTime,
        });
      });
      navigator.mediaSession.setActionHandler("seekto", (details) => {
        if (details.fastSeek && !("fastSeek" in playerRef.current)) {
          return; // fastSeek not supported
        }
        const newTime = details.seekTime;
        const duration = playerRef.current.getDuration();
        if (newTime >= 0 && newTime <= duration) {
          playerRef.current.seekTo(newTime);
          // Update position state after seeking
          navigator.mediaSession.setPositionState({
            duration: duration,
            playbackRate: 1,
            position: newTime,
          });
        }
      });
    }
  }, [props.episode, props.episode?.feedImage]);
  const [currentTranscriptText, setCurrentTranscriptText] = useState("");
  const [currentParagraphStart, setCurrentParagraphStart] = useState(0);
  const [currentParagraphEnd, setCurrentParagraphEnd] = useState(0);
  const { UIState, setUIState } = useUIState();
  const { startEpisodePolling, LibraryData, addEpisodeToFolder, toggleStarEpisode } = useLibraryData();

  const handleAddEpisodeToFolder = useCallback(
    async (folderId, episodeId) => {
      try {
        await addEpisodeToFolder(folderId, episodeId);
        toast.success("Episode added to folder");
      } catch (error) {
        console.error("Error adding episode to folder:", error);
        toast.error("Failed to add episode to folder");
      }
    },
    [addEpisodeToFolder]
  );

  const handleEditorSave = async (content) => {
    try {
      await axiosPrivate.post("/users/notes", {
        data: content.data,
        episode_id: content.episode_id,
      });
      console.log("Note saved successfully!");
    } catch (err) {
      console.error("Error saving note:", err);
      toast.error("Failed to save note");
    }
  };

  const handleInboxSidebar = () => {
    setUIState({
      ...UIState,
      inboxSidebarOpen: !UIState.inboxSidebarOpen,
    });
  };

  // handle on close the podscribe modal window
  const HandleOnClosePodscribeModal = () => {
    setShowPodscribeModal(false);
  };
  // handle on close the podscribe modal window
  const ShowPodscribeModal = () => {
    setShowPodscribeModal(true);
  };

  // useRef for the Audio Player
  const playerRef = useRef(null);

  // Function to check if the URL is a YouTube link
  const isYouTubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Memoize the result of isYouTubeUrl
  const isYouTube = useMemo(() => isYouTubeUrl(props.episode.enclosureUrl), [props.episode.enclosureUrl]);

  // control the player based on the clicked segment

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = showFullDescription ? props.episode.description : `${props.episode.description.slice(0, 100)}...`;

  useEffect(() => {
    // This effect will run on every render
    setPlayAudio(false);
  }, [props.selectedEpisodeState]); // Empty dependency array means this runs once on mount and on every rerender

  const SelectSegmentHandler = (time) => {
    playerRef.current.seekTo(time, "seconds");
    // start playing
    setPlayAudio(true);
  };

  // HandleTranscript button. Show transcribe set to True or False
  const HandleShowTranscript = () => {
    setShowTranscribe(!showTranscribe);
  };

  // HandleEditor button. Show Editor set to True or False
  const HandleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  // Handle Toggle Episode Description
  const HandleToggleEpisodeDescription = () => {
    setToggleEpisodeDescription(!toggleEpisodeDescription);
  };

  // Handle Transcribe button. Show transcribe set to True or False
  const HandleTranscribeEpisode = () => {
    // Calculate minutes to be used
    const minutesToUse = Math.ceil(props.episode.duration / 60);

    // send API request
    const response = axiosPrivate
      .post(`/podcast/transcription/${props.episode.id}`)
      .then((response) => {
        console.log(response);
        toast.success("Episode transcribing. Please wait a few minutes...");
        setTranscribeInProgress(true);
        // set the episode state to transcribe in progress
        props.episode.is_processing = true;
        startEpisodePolling(props.episode.feedId, props.episode.id, 5000, 300000);
        // close modal
        setShowPodscribeModal(false);
        // update the user minutes
        auth.user.podscribe_minutes = auth.user.podscribe_minutes - minutesToUse;
        if (auth.user.podscribe_minutes < 0) {
          auth.user.podscribe_topup_minutes += auth.user.podscribe_minutes;
          auth.user.podscribe_minutes = 0;
        }
      })
      .catch((error) => {
        // print error details if present
        error &&
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          toast.error(error.response.data.detail);
        console.log(error);
      });
  };
  // Handle Upvote of the episode using the /podcast/upvote/<episode_id> API

  const HandleUpvoteEpisode = () => {
    // send API request
    const response = axiosPrivate
      .post(`/podcast/upvote/${props.episode.id}`)
      .then((response) => {
        console.log(response);
        toast.success("Episode Like recorded. Thank you!");
      })
      .catch((error) => {
        // print error details if present
        error &&
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          toast.error(error.response.data.detail);
        console.log(error);
      });
  };

  const HandleShareEpisode = () => {
    const isSubscribed = auth.user.subscription_status === "active";
    let shareLinkUrl;

    if (isSubscribed) {
      axiosPrivate
        .post(`/podcast/episode/${props.episode.id}/auth-link`)
        .then((response) => {
          const shareLink = response.data.key;
          shareLinkUrl = `https://podscribe.io/content/podcasts/${props.episode.feedId}/episode/${props.episode.id}?auth=${shareLink}`;
          setShareUrl(shareLinkUrl);
          setIsShareModalOpen(true);
        })
        .catch((error) => {
          console.error("Error generating share link:", error);
          toast.error("Failed to generate share link. Please try again.");
        });
    } else {
      // Generate example data for non-subscribed users
      shareLinkUrl = `https://podscribe.io/content/podcasts/example-feed/episode/example-episode?auth=example-key`;
      setShareUrl(shareLinkUrl);
      setIsShareModalOpen(true);
    }
  };

  const handleCopyToClipboard = () => {
    copy(shareUrl);
    setIsShareModalOpen(false);
  };

  return (
    <section
      aria-labelledby="message-heading"
      className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last">
      {/* Top section */}
      <div className="flex-shrink-0 bg-white border-b border-neutral-200">
        {/* Toolbar*/}

        <div className="h-16 flex flex-col justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div className="">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    className="lg:hidden mr-5 relative inline-flex items-center px-2 py-2 rounded-md border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-support-yellow-600 focus:border-support-yellow-600"
                    onClick={() => handleInboxSidebar()}>
                    <span className="sr-only">Open Mobile sidebar</span>
                    <MenuAlt1Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <div className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      onClick={ShowPodscribeModal}
                      disabled={props.episode.is_summarized}
                      className={`relative inline-flex items-center sm:px-4 px-2 py-1 rounded-l-md border border-neutral-300 bg-white text-sm font-medium ${
                        props.episode.is_summarized ? "text-neutral-400" : "text-primary-600"
                      } hover:bg-neutral-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary-400 focus:border-secondary-400`}>
                      <LightningBoltIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="hidden sm:block ml-2">Podscribe</span>
                    </button>
                    <button
                      onClick={() => setIsCommandPaletteOpen(true)}
                      className="relative -ml-px inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border border-neutral-300 bg-white text-sm font-medium text-primary-500 hover:bg-neutral-50">
                      <span className="sr-only">Open command palette</span>
                      <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <CommandPalette
                      isOpen={isCommandPaletteOpen}
                      onClose={() => setIsCommandPaletteOpen(false)}
                      onCommandSelect={(action) => {
                        switch (action) {
                          case "share":
                            HandleShareEpisode();
                            break;
                          case "export":
                            setIsExportModalOpen(true);
                            break;
                          case "star":
                          case "unstar":
                            toggleStarEpisode(props.episode.id);
                            break;
                          case "like":
                            HandleUpvoteEpisode();
                            break;
                          case "addToFolder":
                            setIsFolderModalOpen(true);
                            break;
                          case "download":
                            window.open(props.episode.enclosureUrl, "_blank");
                            break;
                          case "bookmark":
                            alert("Bookmark feature coming soon!");
                            break;
                          case "speed":
                            alert("Speed settings coming soon!");
                            break;
                          case "timer":
                            alert("Sleep timer coming soon!");
                            break;
                          case "chapters":
                            alert("Chapters feature coming soon!");
                            break;
                          case "report":
                            alert("Report issue coming soon!");
                            break;
                          case "remove":
                            props.RemoveEpisodeHandler(props.episode.feedId, props.episode.id);
                            break;
                        }
                      }}
                    />
                  </div>
                </span>
              </div>

              {/* Right buttons */}
              <nav aria-label="Pagination">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    onClick={props.handleOpenSearchModal}
                    className="relative inline-flex items-center sm:px-4 px-2 py-2 rounded-l-md border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50 focus:ring-1">
                    <span className="sr-only">Previous</span>
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={props.SelectPreviousEpisodeHandler}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50  ">
                    <span className="sr-only">Previous</span>
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={props.SelectNextEpisodeHandler}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border border-neutral-300 bg-white text-sm font-medium text-neutral-500 hover:bg-neutral-50  ">
                    <span className="sr-only">Next</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </nav>
            </div>
          </div>
        </div>
        {/* Message header */}
      </div>

      <div className="bg-white shadow">
        <div className="flex flex-col px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
              <Img
                src={props.episode.feedImage}
                className="h-16 w-16 text-neutral-400 rounded-lg object-cover shadow-sm"
                aria-hidden="true"
              />
            </div>
            <div className="min-w-0 flex-1">
              <div className="flex items-center justify-between">
                <h1 className="text-lg font-medium text-primary-700 truncate">
                  <a
                    href={props.episode.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline inline-flex items-center">
                    <span className="mr-2">{props.episode.title}</span>
                    <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  </a>
                </h1>
                <div className="ml-4 flex-shrink-0">
                  <BeatLoader color={"#4C63B6"} loading={props.episode.is_processing} />
                </div>
              </div>
              <p className="text-sm font-medium text-secondary-700">{props.episode.podcast_title}</p>
              {toggleEpisodeDescription && (
                <div className="mt-2 text-sm text-gray-600">
                  <p className="prose prose-sm">
                    {remove_html_tags(description)}
                    {props.episode.description.length > 100 && (
                      <button className="ml-1 text-primary-500 hover:underline" onClick={toggleDescription}>
                        {showFullDescription ? "Less" : "More"}
                      </button>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Player */}
          <div className="mt-4">
            <div
              className={`relative ${
                isYouTube || props.episode.content_type === "youtube" ? "h-[100px]" : "h-[40px]"
              } w-full rounded-lg overflow-hidden shadow-sm bg-gray-50`}>
              <ReactPlayer
                key={props.episode.id}
                ref={playerRef}
                url={props.episode.enclosureUrl}
                playing={playAudio}
                controls={true}
                width="100%"
                height={isYouTube || props.episode.content_type === "youtube" ? "100px" : "40px"}
                className="absolute"
                onPlay={() => {
                  setPlayAudio(true);
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = "playing";
                  }
                }}
                onPause={() => {
                  setPlayAudio(false);
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = "paused";
                  }
                }}
                onEnded={() => {
                  setPlayAudio(false);
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.playbackState = "none";
                  }
                }}
                onProgress={({ played, playedSeconds }) => {
                  if ("mediaSession" in navigator) {
                    navigator.mediaSession.setPositionState({
                      duration: playerRef.current?.getDuration() || 0,
                      playbackRate: 1,
                      position: playedSeconds,
                    });
                  }
                }}
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 0,
                      controls: 1,
                      modestbranding: 1,
                    },
                  },
                  file: {
                    forceAudio: !(isYouTube || props.episode.content_type === "youtube"),
                    attributes: {
                      style: { width: "100%", height: "40px" },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8">
          {showTranscribe ? (
            <InboxContent
              data={props.data}
              SelectSegmentHandler={SelectSegmentHandler}
              onEditorChange={props.onEditorChange}
              editorData={props.EditorData}
              showEditor={showEditor}
              episode={props.episode}
              selectedEpisodeState={props.selectedEpisodeState}
              onNoteEditorChange={handleEditorSave}
              HandleTranscribeEpisode={ShowPodscribeModal}
            />
          ) : null}
        </div>
      </div>
      <PodscribeActionModal
        show={showPodscribeModal}
        HandleOnClose={HandleOnClosePodscribeModal}
        HandleOnAction={HandleTranscribeEpisode}
        ActionButtonText="Podscribe"
        CloseButtonText="Cancel"
        title="Process episode"
        availableMinutes={auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes}
        requiredMinutes={Math.ceil(props.episode.duration / 60)}
        BodyText={
          <div className="space-y-4">
            <h4 className="font-semibold text-lg">AI Processing Confirmation</h4>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                You're about to AI process this episode. Here's what you need to know:
              </p>
              <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                <li>
                  Estimated cost:{" "}
                  <span className="font-semibold text-gray-800">{Math.ceil(props.episode.duration / 60)} minutes</span>
                </li>
                <li>
                  Your available balance:{" "}
                  <span className="font-semibold text-gray-800">
                    {auth.user.podscribe_minutes + auth.user.podscribe_topup_minutes} minutes
                  </span>
                  {auth.user.subscription_status === "active" ? " (subscription + top-up)" : ""}
                </li>
                <li>Actual processing time may vary based on episode length and system workload</li>
                <li>Typical completion time: A few minutes</li>
              </ul>
            </div>
            {auth.user.subscription_status !== "active" && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      As a free user, you have a limit of 100 minutes. Once exhausted, you'll need to subscribe to
                      continue processing new content.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <p className="font-medium">Are you sure you want to proceed with processing this episode?</p>
          </div>
        }
      />
      {/* Timestamp note button - only visible when episode is summarized */}
      {props.episode.is_summarized && (
        <div className="fixed bottom-8 right-8 z-[5]">
          <button
            onClick={() => {
              const currentTime = Math.floor(playerRef.current.getCurrentTime());
              const relevantText = props.selectedEpisodeState.episode.transcription?.paragraphs.paragraphs.find(
                (p) => currentTime >= p.start && currentTime <= p.end
              );
              setCurrentTimestamp(currentTime);
              setCurrentTranscriptText(relevantText || null);
              setIsTimestampModalOpen(true);
              // Store paragraph timing if found
              if (relevantText) {
                setCurrentParagraphStart(relevantText.start);
                setCurrentParagraphEnd(relevantText.end);
              }
            }}
            className="group relative flex items-center justify-center w-12 h-12 rounded-2xl bg-white/90 shadow-lg hover:shadow-xl backdrop-blur-md transition-all duration-300 ease-out border border-secondary-600 hover:ring-2 hover:ring-secondary-600/50 hover:border-secondary-600/50"
            title="Add Highlight"
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.1), 0 8px 16px rgba(76,99,182,0.12)",
              transform: "translateZ(0)",
            }}
            aria-label="Add Highlight">
            <div className="absolute inset-0 bg-gradient-to-br from-secondary-500/10 to-secondary-600/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            <PencilAltIcon
              className="w-6 h-6 text-secondary-600 transform transition-all duration-300 ease-out group-hover:scale-110 group-active:scale-95"
              style={{ filter: "drop-shadow(0 1px 1px rgba(0,0,0,0.05))" }}
            />
          </button>
        </div>
      )}
      <TimestampNoteModal
        show={isTimestampModalOpen}
        onClose={() => setIsTimestampModalOpen(false)}
        timestamp={currentTimestamp}
        transcriptText={currentTranscriptText}
        paragraphStart={currentParagraphStart}
        paragraphEnd={currentParagraphEnd}
        onSave={({ timestamp, note, transcriptText }) => {
          const timestampStr = `${Math.floor(timestamp / 60)}:${(timestamp % 60).toString().padStart(2, "0")}`;
          const transcriptTextContent =
            transcriptText?.sentences?.map((s) => s.text).join(" ") || "No transcript available";
          const noteText = `\n\n# Audio Highlight [${timestampStr}]\n\n >${transcriptTextContent}\n\n## My Notes\n${note}\n\n---\n`;

          // Append to existing notes
          const updatedNote = (props.episode.note || "") + noteText;
          props.selectedEpisodeState.episode.note = updatedNote;
          handleEditorSave({
            episode_id: props.episode.id,
            data: updatedNote,
          });

          setIsTimestampModalOpen(false);
          setPlayAudio(true);
        }}
      />
      <ShareEpisodeModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        shareUrl={shareUrl}
        onCopyToClipboard={handleCopyToClipboard}
        isSubscribed={auth.user.subscription_status === "active"}
      />
      <ActionModalPleaseSubscribeToUse
        show={showSubscribeModal}
        HandleOnClose={() => setShowSubscribeModal(false)}
        title="Subscribe to Share"
        BodyText="To share episodes, you need an active subscription. Subscribe now to unlock this feature and many more!"
        CloseButtonText="Close"
      />
      <ExportModal show={isExportModalOpen} onClose={() => setIsExportModalOpen(false)} episode={props.episode} />
      <FolderSelectionModal
        show={isFolderModalOpen}
        onClose={() => setIsFolderModalOpen(false)}
        folders={LibraryData.folders}
        episodeId={props.episode?.id}
      />
    </section>
  );
};

export default InboxMessage;
