import React, { useState, useContext, useEffect } from "react";
import { Tab } from "@headlessui/react";
import LinkInput from "../LinkInput";
import FileDropzone from "../FileDropzone";
import YouTubeInput from "./YouTubeInput";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-toastify";
import AuthContext from "../../../context/AuthProvider";
import useLibraryData from "../../../hooks/useLibraryData";
import { InformationCircleIcon, DocumentIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomUploadsTab({ onClose }) {
  const [activeTab, setActiveTab] = useState(0);
  const [link, setLink] = useState("");
  const [file, setFile] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFile, setYoutubeFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeData, setYoutubeData] = useState(null);
  const [isLoadingYouTubeData, setIsLoadingYouTubeData] = useState(false);
  const { auth } = useContext(AuthContext);
  const { LibraryData, setLibraryData, refreshLibraryEpisode, removeLibraryEpisode, addLibraryEpisode } =
    useLibraryData();

  const parseYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const fetchYouTubeData = async (videoId) => {
    setIsLoadingYouTubeData(true);
    setYoutubeData(null);
    try {
      const response = await axiosPrivate.get(`/custom/youtube-data/${videoId}`);
      const data = response.data;
      setYoutubeData({
        title: data.title,
        description: data.description,
        thumbnail: data.thumbnail,
      });
      setTitle(data.title);
      setDescription(data.description);
    } catch (error) {
      console.error("Error fetching YouTube data:", error);
      toast.error("Failed to fetch YouTube video data. Please check the link and try again.");
      setYoutubeData(null);
    } finally {
      setIsLoadingYouTubeData(false);
    }
  };

  useEffect(() => {
    if (activeTab === 1 && youtubeLink) {
      const videoId = parseYouTubeId(youtubeLink);
      if (videoId) {
        fetchYouTubeData(videoId);
      }
    }
  }, [youtubeLink, activeTab]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!auth.user || auth.user.subscription_status !== "active") {
      toast.error("You need an active subscription to use this feature.");
      setIsLoading(false);
      return;
    }

    try {
      let response;
      const formData = new FormData();

      // Get the selected language value
      const selectedLanguage = document.getElementById("language").value;

      if (activeTab === 0) {
        // Link
        formData.append("link", link);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("is_private", "true");
        formData.append("content_type", "custom");
        formData.append("language", selectedLanguage);
      } else if (activeTab === 1) {
        // YouTube
        if (!youtubeFile || !youtubeLink) {
          throw new Error("Please provide both a YouTube link and an audio file.");
        }
        formData.append("file", youtubeFile);
        const youtubeId = parseYouTubeId(youtubeLink);
        if (!youtubeId) {
          throw new Error("Invalid YouTube link");
        }
        formData.append("youtube_id", youtubeId);
        formData.append("title", youtubeData ? youtubeData.title : title);
        formData.append("description", youtubeData ? youtubeData.description : description);
        formData.append("is_private", "true");
        formData.append("content_type", "youtube");
        formData.append("youtube_thumbnail", youtubeData ? youtubeData.thumbnail : "");
        formData.append("language", selectedLanguage);
      } else {
        // File
        if (!file) {
          throw new Error("Please upload a file.");
        }
        formData.append("file", file);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("is_private", "true");
        formData.append("content_type", "custom");
        formData.append("language", selectedLanguage);
      }

      response = await axiosPrivate.post("/custom/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLibraryData((prevData) => ({
        ...prevData,
        episodes: [...prevData.episodes, response.data],
      }));

      toast.success("Content uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Error uploading content:", error);
      toast.error(error.message || "Failed to upload content. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Custom Uploads</h2>
      <p className="text-sm text-gray-500 mb-4">
        Upload your own content to your inbox. You can add links, YouTube videos, or audio files.
      </p>
      {(!auth.user || auth.user.subscription_status !== "active") && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <p className="text-sm text-yellow-700">
            You need an active subscription to use this feature. Please upgrade your account.
          </p>
        </div>
      )}
      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {["Link", "YouTube", "File"].map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected ? "bg-white shadow" : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }>
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>
            <LinkInput link={link} setLink={setLink} />
          </Tab.Panel>
          <Tab.Panel>
            <YouTubeInput youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink} />
            <div className="mt-4 bg-blue-50 border-l-4 border-blue-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-blue-700">
                    <strong>Important:</strong> You need to upload the audio file of the YouTube video yourself. We cannot fetch the audio on your behalf due to YouTube's terms of service.
                  </p>
                </div>
              </div>
            </div>
            <FileDropzone file={youtubeFile} setFile={setYoutubeFile} maxSize={300 * 1024 * 1024} />
            {youtubeFile && (
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <DocumentIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>{youtubeFile.name}</span>
              </div>
            )}
            {isLoadingYouTubeData && <p className="mt-2 text-sm text-gray-500">Loading YouTube data...</p>}
            {youtubeData && (
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-700">Video Information:</h4>
                <div className="flex items-start mt-2">
                  <img src={youtubeData.thumbnail} alt="Video thumbnail" className="w-32 h-auto mr-4 rounded" />
                  <div>
                    <p className="text-sm text-gray-700 font-medium">{youtubeData.title}</p>
                    <p className="text-sm text-gray-500 mt-1">
                      {youtubeData.description.slice(0, 100)}
                      {youtubeData.description.length > 100 ? "..." : ""}
                    </p>
                  </div>
                </div>
                <div className="mt-4 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700">
                        <strong>Important:</strong> Please respect YouTube's Terms of Service. Only import videos
                        that you have created or have explicit permission to use.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700">
                    Download Audio (for content you have rights to):
                  </h4>
                  <p className="text-sm text-gray-500 mt-1">
                    You can use{" "}
                    <a
                      href="https://github.com/yt-dlp/yt-dlp"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      yt-dlp
                    </a>{" "}
                    to download the audio if you have the right to do so.
                  </p>
                  <div className="mt-2 bg-gray-100 p-2 rounded-md">
                    <p className="text-sm font-mono break-all">
                      yt-dlp -x --audio-format mp3 -o "%(id)s.%(ext)s" {youtubeLink}
                    </p>
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                    Note: Ensure you have the necessary rights before downloading any content.
                  </p>
                </div>
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel>
            <FileDropzone file={file} setFile={setFile} maxSize={300 * 1024 * 1024} />
            {file && (
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <DocumentIcon className="mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>{file.name}</span>
              </div>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="mt-4">
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          Title
        </label>
        <input
          type="text"
          name="title"
          id="title"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="mt-4">
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          name="description"
          id="description"
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>
      <div className="mt-4">
        <label htmlFor="language" className="block text-sm font-medium text-gray-700">
          Language
        </label>
        <select
          name="language"
          id="language"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          defaultValue="en"
        >
          <option value="bg">Bulgarian</option>
          <option value="ca">Catalan</option>
          <option value="zh">Chinese</option>
          <option value="zh-CN">Chinese (Simplified)</option>
          <option value="zh-Hans">Chinese (Simplified)</option>
          <option value="zh-TW">Chinese (Traditional)</option>
          <option value="zh-Hant">Chinese (Traditional)</option>
          <option value="zh-HK">Chinese (Hong Kong)</option>
          <option value="cs">Czech</option>
          <option value="da">Danish</option>
          <option value="da-DK">Danish (Denmark)</option>
          <option value="nl">Dutch</option>
          <option value="en">English</option>
          <option value="en-US">English (US)</option>
          <option value="en-AU">English (Australia)</option>
          <option value="en-GB">English (UK)</option>
          <option value="en-NZ">English (New Zealand)</option>
          <option value="en-IN">English (India)</option>
          <option value="et">Estonian</option>
          <option value="fi">Finnish</option>
          <option value="nl-BE">Flemish</option>
          <option value="fr">French</option>
          <option value="fr-CA">French (Canada)</option>
          <option value="de">German</option>
          <option value="de-CH">German (Switzerland)</option>
          <option value="el">Greek</option>
          <option value="hi">Hindi</option>
          <option value="hu">Hungarian</option>
          <option value="id">Indonesian</option>
          <option value="it">Italian</option>
          <option value="ja">Japanese</option>
          <option value="ko">Korean</option>
          <option value="ko-KR">Korean (South Korea)</option>
          <option value="lv">Latvian</option>
          <option value="lt">Lithuanian</option>
          <option value="ms">Malay</option>
          <option value="multi">Multiple Languages</option>
          <option value="no">Norwegian</option>
          <option value="pl">Polish</option>
          <option value="pt">Portuguese</option>
          <option value="pt-BR">Portuguese (Brazil)</option>
          <option value="pt-PT">Portuguese (Portugal)</option>
          <option value="ro">Romanian</option>
          <option value="ru">Russian</option>
          <option value="sk">Slovak</option>
          <option value="es">Spanish</option>
          <option value="es-419">Spanish (Latin America)</option>
          <option value="sv">Swedish</option>
          <option value="sv-SE">Swedish (Sweden)</option>
          <option value="th">Thai</option>
          <option value="th-TH">Thai (Thailand)</option>
          <option value="tr">Turkish</option>
          <option value="uk">Ukrainian</option>
          <option value="vi">Vietnamese</option>
        </select>
      </div>
      <div className="mt-4">
        <button
          type="button"
          onClick={handleSubmit}
          disabled={isLoading || !auth.user || auth.user.subscription_status !== "active"}
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed">
          {isLoading ? "Uploading..." : "Upload Content"}
        </button>
      </div>
    </div>
  );
}
