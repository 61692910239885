import React, { useEffect, useState, useCallback, useRef } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useAuth from "../../hooks/useAuth";
import { BeatLoader } from "react-spinners";

const MAX_RETRIES = 5;
const INITIAL_DELAY = 5000; // 5 seconds

export default function PricingSuccess() {
  const { auth, refreshUserData } = useAuth();
  const [subscriptionDeliveryCheck, setSubscriptionDeliveryCheck] = useState(true);
  const [error, setError] = useState(null);

  const timeoutRef = useRef(null);

  const checkSubscriptionUpdate = useCallback(
    async (retryCount = 0) => {
      if (retryCount >= MAX_RETRIES) {
        setSubscriptionDeliveryCheck(false);
        setError("Max retries reached. Subscription update may not have been successful.");
        return;
      }

      try {
        await refreshUserData();

        if (auth.user.subscription_status === "active") {
          console.log("Subscription active");
          setSubscriptionDeliveryCheck(false);
          return;
        }

        // If we're here, the update hasn't been reflected yet. Retry with exponential backoff.
        const nextDelay = INITIAL_DELAY * Math.pow(2, retryCount);
        timeoutRef.current = setTimeout(() => checkSubscriptionUpdate(retryCount + 1), nextDelay);
        console.log("Retrying subscription update check...");
        console.log("Next delay:", nextDelay);
        console.log("Auth user object:", auth.user);
      } catch (error) {
        console.error("Error refreshing user data:", error);
        setError("An error occurred while checking the subscription update. Please try again later.");
        setSubscriptionDeliveryCheck(false);
      }
    },
    [auth.user.subscription_status]
  );

  useEffect(() => {
    if (subscriptionDeliveryCheck) {
      checkSubscriptionUpdate();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [checkSubscriptionUpdate, subscriptionDeliveryCheck]);

  return (
    <>
      <div className=" bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
        {/* Page Header */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-primary-600">Subscription</h3>
            <p className="text-sm leading-6 text-neutral-500">Success! Your subscription has been processed.</p>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <CheckCircleIcon className="h-24 w-24 text-primary-600 mx-auto" />
            <p className="mt-2 text-4xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
              We are thrilled to have you on board!
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            Your subscription has been successfully processed. Please allow a few minutes for the changes to take
            effect, to access all our features.
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            If you have any questions, please contact us at{" "}
            <a href="mailto:info@podscribe.io" className="text-primary-600">
              info@podscribe.io
            </a>
            .
          </p>
        </div>
        {/* Loading spinner or status message */}
        <div className="flex justify-center items-center mt-6">
          {subscriptionDeliveryCheck ? (
            <BeatLoader color={"#fca311"} loading={subscriptionDeliveryCheck} size={15} />
          ) : error ? (
            <p className="text-center text-lg text-red-600">{error}</p>
          ) : (
            <p className="text-center text-lg text-support-green-600">Subscription configured successfully!</p>
          )}
        </div>
      </div>
    </>
  );
}
