import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { axiosPublic } from "../../../common/axiosPublic";
import Img from "../../../UI/Img";
import { FadeLoader } from "react-spinners";
import SEO from "../../SEO";
import { Helmet } from "react-helmet";

const PodcastsGrid = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        const response = await axiosPublic.get("/repository/content/podcasts");
        setPodcasts(response.data.podcasts);
      } catch (error) {
        console.error("Error fetching podcasts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPodcasts();
  }, []);

  return (
    <>
      <SEO
        title="Best Podcast Notes | PodScribe.IO"
        description="Discover and enjoy the best podcast notes, summaries, and insights from a wide range of popular podcasts. Enhance your learning with PodScribe.IO's curated collection of podcast episode notes."
        type="website"
        url="https://podscribe.io/content/podcasts"
        image="https://podscribe.io/og-image.jpg" // Replace with an appropriate image URL
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://podscribe.io/content/podcasts",
            },
            headline: "Best Podcast Notes | PodScribe.IO",
            description:
              "Discover and enjoy the best podcast notes, summaries, and insights from a wide range of popular podcasts. Enhance your learning with PodScribe.IO's curated collection of podcast episode notes.",
            publisher: {
              "@type": "Organization",
              name: "PodScribe.IO",
              logo: {
                "@type": "ImageObject",
                url: "https://podscribe.io/og-image.jpg",
              },
            },
          })}
        </script>
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-primary-800">Enjoy the best Podcast Notes</h1>
          <p className="text-lg text-gray-600 mt-2">
            Browse our collection of podcast episode notes, summaries, insights
          </p>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-96">
            <FadeLoader color={"#fca311"} loading={isLoading} size={150} />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {podcasts.map((podcast) => (
              <div
                key={podcast.id}
                className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
                <div className="relative pb-[56.25%]">
                  {" "}
                  {/* 16:9 aspect ratio */}
                  <Img
                    src={podcast.image}
                    alt={podcast.title}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-4 flex flex-col h-48">
                  {" "}
                  {/* Fixed height for content */}
                  <Link to={`/content/podcasts/${podcast.id}/episodes`} className="group">
                    <h3 className="text-lg font-semibold text-primary-600 truncate flex items-center group-hover:text-primary-800">
                      {podcast.title}
                      <ChevronRightIcon className="h-5 w-5 ml-1 text-primary-600 group-hover:text-primary-800 transition-transform duration-300 group-hover:translate-x-1" />
                    </h3>
                  </Link>
                  <p className="text-sm text-secondary-500 mt-2">
                    {podcast.number_of_episodes_transcribed} episode notes
                  </p>
                  <p className="text-sm text-gray-600 mt-2 line-clamp-3 flex-grow">{podcast.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PodcastsGrid;
