import React from "react";
import { Button } from "./Button";

export function EnterprisePlan() {
  return (
    <section className="rounded-3xl bg-primary-700 px-6 py-8 sm:px-8 shadow-md shadow-primary-900">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <h3 className="font-display text-3xl text-white">Enterprise</h3>
      </div>
      <div className="mt-8 flex flex-col lg:flex-row lg:items-start lg:justify-between">
        <div className="lg:w-1/2">
          <p className="text-base text-white">For organizations that require a custom plan tailored to their needs.</p>
          <p className="mt-4 text-sm text-white">
            Contact us for an enterprise plan if you want volume based discounts, enterprise-level SLAs, dedicated
            support, priority access to features, and more.
          </p>
          <Button
            href="mailto:info@podscribe.io"
            variant="outline"
            color="white"
            className="mt-8"
            aria-label="Contact us for Enterprise plan">
            Contact Us
          </Button>
        </div>
        <ul className="mt-8 lg:mt-0 lg:w-1/2 flex flex-col gap-y-3 text-sm text-white">
          <li className="flex">
            <CheckIcon className="text-white" />
            <span className="ml-4">Scalable pricing with volume based discounts</span>
          </li>
          <li className="flex">
            <CheckIcon className="text-white" />
            <span className="ml-4">Custom integrations</span>
          </li>
          <li className="flex">
            <CheckIcon className="text-white" />
            <span className="ml-4">API access</span>
          </li>
          <li className="flex">
            <CheckIcon className="text-white" />
            <span className="ml-4">Dedicated support</span>
          </li>
        </ul>
      </div>
    </section>
  );
}

function CheckIcon({ className }) {
  return (
    <svg aria-hidden="true" className={`h-6 w-6 flex-none fill-current stroke-current ${className}`}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        strokeWidth={0}
      />
      <circle cx={12} cy={12} r={8.25} fill="none" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
