import { MailIcon } from "@heroicons/react/outline";

export default function Button(props) {
  const { children, className, ...rest } = props;
  return (
    <button
      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
