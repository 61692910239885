import React from "react";
import { Link } from "react-router-dom";
import { ExternalLinkIcon, CalendarIcon, MicrophoneIcon } from "@heroicons/react/solid";
import Img from "../../../UI/Img";

const PodcastHeader = ({ podcast }) => {
  if (!podcast) return null;

  return (
    <div className="bg-neutral-50 ring-1 ring-primary-600 ring-opacity-40 rounded-lg shadow-lg overflow-hidden mb-8">
      <div className="p-4 sm:p-6 md:p-8 flex flex-col md:flex-row items-start md:space-x-6">
        <Img
          src={podcast.image}
          alt={podcast.title}
          className="h-24 w-24 sm:h-32 sm:w-32 md:h-40 md:w-40 rounded-lg shadow-md flex-shrink-0 mx-auto md:mx-0 mb-4 md:mb-0"
        />
        <div className="flex-grow w-full md:w-auto">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <div>
              <Link to={podcast.link} target="_blank" className="group inline-flex items-center">
                <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-primary-800 group-hover:text-secondary-700 transition duration-150">
                  {podcast.title}
                </h1>
              </Link>
              <h2 className="text-base sm:text-lg text-secondary-600 mt-1">
                by <span className="font-semibold">{podcast.author}</span>
              </h2>
            </div>
          </div>
          <div className="flex flex-wrap items-center mt-3 space-x-4 text-sm text-primary-600">
            <Link to={podcast.link} target="_blank" className="flex items-center hover:text-secondary-600">
              <ExternalLinkIcon className="w-4 h-4 mr-1" />
              Podcast Link
            </Link>
            <span className="flex items-center">
              <MicrophoneIcon className="w-4 h-4 mr-1" />
              {podcast.number_of_episodes_transcribed} processed Episodes
            </span>
            {podcast.lastEpisodeDate && (
              <span className="flex items-center">
                <CalendarIcon className="w-4 h-4 mr-1" />
                Last episode: {new Date(podcast.lastEpisodeDate).toLocaleDateString()}
              </span>
            )}
          </div>
          <p className="mt-3 md:mt-4 text-sm sm:text-base text-primary-700 line-clamp-3">{podcast.description}</p>
          <div className="flex flex-wrap mt-3 md:mt-4 gap-2">
            {podcast.categories &&
              Object.values(podcast.categories).map((category, i) => (
                <span
                  key={i}
                  className="inline-flex items-center rounded-full bg-primary-50 px-2 py-1 text-xs font-medium text-primary-700 ring-1 ring-inset ring-primary-300">
                  {category}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastHeader;
