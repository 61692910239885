import React, { useState, useEffect } from "react";
import { PhotographIcon } from "@heroicons/react/solid";

function testImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(url);
    img.onerror = () => resolve(null);
    img.src = url;
  });
}

function Img({ src, alt, ...rest }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    testImage(src).then((url) => {
      setImageUrl(url);
    });
  }, [src]);

  return (
    <>
      {imageUrl ? (
        <img src={imageUrl} alt={alt} {...rest} />
      ) : (
        <div>
          <PhotographIcon {...rest} />
        </div>
      )}
    </>
  );
}

export default Img;
