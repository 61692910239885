import { Fragment, useState } from "react";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog } from "@headlessui/react";
import {
  BookmarkIcon,
  BookmarkAltIcon,
  FastForwardIcon,
  ClockIcon,
  BookOpenIcon,
  FlagIcon,
  ShareIcon,
  DocumentTextIcon,
  FolderDownloadIcon,
  TrashIcon,
  PlusIcon,
  ThumbUpIcon,
  FolderIcon,
  StarIcon,
} from "@heroicons/react/solid";

const commands = [
  { id: 1, name: "Share Episode", icon: ShareIcon, action: "share" },
  { id: 2, name: "Export", icon: DocumentTextIcon, action: "export" },
  { id: 3, name: "Like", icon: ThumbUpIcon, action: "Like Episode" },
  { id: 4, name: "Add/Remove to Folder", icon: FolderIcon, action: "addToFolder" },
  { id: 5, name: "Download/Open Link", icon: FolderDownloadIcon, action: "download" },
  { id: 6, name: "Star/Unstar Episode", icon: StarIcon, action: "star" },
  { id: 8, name: "Remove", icon: TrashIcon, action: "remove" },
];

export default function CommandPalette({ isOpen, onClose, onCommandSelect }) {
  const [query, setQuery] = useState("");

  const filteredCommands =
    query === ""
      ? commands
      : commands.filter((command) => {
          return command.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Dialog
      className="relative z-50"
      open={isOpen}
      onClose={() => {
        onClose();
        setQuery("");
      }}>
      <Dialog.Overlay className="fixed inset-0 bg-neutral-500/25 transition-opacity" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-primary-900/5 transition-all">
          <Combobox
            onChange={(command) => {
              if (command) {
                onCommandSelect(command.action);
                onClose();
                setQuery("");
              }
            }}>
            <div className="relative">
              <Combobox.Input
                autoFocus
                className="w-full rounded-md bg-neutral-100 px-4 py-2.5 text-neutral-900 outline-none placeholder:text-neutral-500 sm:text-sm pr-20"
                placeholder="Search commands..."
                onChange={(event) => setQuery(event.target.value)}
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-neutral-400">
                {navigator.platform.includes("Mac") ? "⌘" : "Ctrl"} + P
              </span>
            </div>

            {filteredCommands.length > 0 && (
              <Combobox.Options static className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                {filteredCommands.map((command) => (
                  <Combobox.Option
                    key={command.id}
                    value={command}
                    className="cursor-default select-none rounded-md px-4 py-2 hover:bg-primary-600 hover:text-white">
                    <div className="flex items-center">
                      <command.icon className="h-5 w-5 mr-2" aria-hidden="true" />
                      {command.name}
                    </div>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}

            {query !== "" && filteredCommands.length === 0 && (
              <div className="px-4 py-14 text-center sm:px-14">
                <p className="text-sm text-neutral-900">No commands found using that search term.</p>
              </div>
            )}
          </Combobox>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
