// this is a function that will remove every html tag from a string.

export function remove_html_tags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, "");
}

export function Highlight({
  text = "",
  highlight = "",
  className = "",
  context = 50,
}) {
  if (!highlight.trim()) {
    return <span className={className}>{"No match"}</span>;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const match = regex.exec(text);
  if (!match) {
    return <span className={className}>{"No match"}</span>;
  }
  let start = Math.max(0, match.index - context);
  let end = Math.min(text.length, match.index + match[0].length + context);
  // Find the nearest space to the left of the start index
  if (text[start] !== " ") {
    const leftSpace = text.lastIndexOf(" ", start);
    if (leftSpace !== -1) {
      start = leftSpace + 1;
    }
  }
  // Find the nearest space to the right of the end index
  if (text[end] !== " ") {
    const rightSpace = text.indexOf(" ", end);
    if (rightSpace !== -1) {
      end = rightSpace;
    }
  }
  const contextText = text.slice(start, end);
  const parts = contextText.split(regex);
  return (
    <span className={`${className} line-clamp-3`}>
      ...
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : part,
        )}
      ...
    </span>
  );
}

export function HighlightWithoutContext({
  text = "",
  highlight = "",
  className = "",
}) {
  if (!highlight.trim()) {
    return <span className={className}>{"No match"}</span>;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const match = regex.exec(text);
  if (!match) {
    return <span className={className}>{"No match"}</span>;
  }
  const parts = text.split(regex);
  return (
    <span className={className}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : part,
        )}
    </span>
  );
}

export function HighlightWithoutContextAndReturnOnNoMatch({
  text = "",
  highlight = "",
  className = "",
}) {
  if (!highlight.trim()) {
    return <span className={className}>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const match = regex.exec(text);
  if (!match) {
    return <span className={className}>{text}</span>;
  }
  const parts = text.split(regex);
  return (
    <span className={className}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : part,
        )}
    </span>
  );
}

const copyToClipboard = async (text) => {
  try {
    const permissions = await navigator.permissions.query({
      name: "clipboard-write",
    });
    if (permissions.state === "granted" || permissions.state === "prompt") {
      await navigator.clipboard.writeText(text);
      alert("Text copied to clipboard!");
    } else {
      throw new Error(
        "Can't access the clipboard. Check your browser permissions.",
      );
    }
  } catch (error) {
    alert("Error copying to clipboard:", error);
  }
};
