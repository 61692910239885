import axios from "axios";

const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

export { axiosPublic };
