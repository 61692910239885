import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SEO from "../SEO";
export default function RegistrationSuccess() {
  return (
    <>
      <SEO title="PodScribe.IO | Welcome!" />
      <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          <CheckCircleIcon className="h-24 w-24 text-primary-600 mx-auto" />
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
            Welcome to PodScribe.IO!
          </h1>
          <p className="mt-6 text-base leading-7 text-neutral-600">
            Your registration has been successfully processed. Please check your email to activate your account.
            <br />
            <br />
            <span className="text-sm text-neutral-600">
              Didn't receive an email? Please check your spam folder or{" "}
              <a href="mailto:info@podscribe.io" className="text-primary-600">
                contact support
              </a>
              .
            </span>
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
              Go back home
            </Link>
            <a href="mailto:info@podscribe.io" className="text-sm font-semibold text-neutral-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
