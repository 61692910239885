import React from "react";
import { useState, useCallback, useMemo, useEffect } from "react";
import { SimpleMdeReact } from "react-simplemde-editor";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import "./editor.css";
import useLibraryData from "../../hooks/useLibraryData";
import { axiosPrivate } from "../../common/axiosPrivate";
import { debounce } from "lodash";
import { FadeLoader } from "react-spinners";
import showdown from "showdown";

const previewRender = (markdownText) => {
  const converter = new showdown.Converter();
  const htmlText = converter.makeHtml(markdownText);
  return htmlText;
};

export const Editor = (props) => {
  const [value, setValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const delay = 1000;

  const debouncedOnChange = useCallback(
    debounce((value) => {
      props.onChange({
        episode_id: props.data.episode.id,
        data: value,
      });
    }, 5000),
    [props.onChange],
  );

  const autofocusNoSpellcheckerOptions = useMemo(() => {
    return {
      autofocus: true,
      spellChecker: false,
      previewRender,
    };
  }, []);

  const handleChange = useCallback(
    (value) => {
      setValue(value);
      debouncedOnChange(value);
    },
    [debouncedOnChange],
  );

  // Trigger onDemand Save when props.saveNow is true
  useEffect(() => {
    if (props.saveNow) {
      props.onChange({
        episode_id: props.data.episode.id,
        data: value,
      });
      console.log("Editor: Triggered onDemand Save");
    }
  }, [props.saveNow]);

  useEffect(() => {
    setValue(props.data.episode.note);
  }, [props.data.episode.note]);

  return (
    <SimpleMdeReact
      placeholder="notes"
      id="notes"
      className="prose prose-sm min-w-full text-primary-800"
      value={value}
      options={autofocusNoSpellcheckerOptions}
      onChange={handleChange}
      style={{ border: "none" }}
    />
  );
};

export default Editor;
