import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosPublic } from "../../../common/axiosPublic";
import Img from "../../../UI/Img";
import { FadeLoader } from "react-spinners";
import EpisodeList from "./EpisodeList";
import PodcastHeader from "./PodcastHeader";
import SEO from "../../SEO";
import { Helmet } from "react-helmet";
import { ExternalLinkIcon } from "@heroicons/react/outline";

const PodcastEpisodes = () => {
  const { podcastId } = useParams();
  const [podcast, setPodcast] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPodcastData = async () => {
      try {
        const episodesResponse = await axiosPublic.get(`/repository/content/podcasts/${podcastId}/episodes`);
        setPodcast(episodesResponse.data.podcast);
        setEpisodes(episodesResponse.data.episodes);
      } catch (error) {
        console.error("Error fetching podcast data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPodcastData();
  }, [podcastId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-96">
        <FadeLoader color={"#fca311"} loading={isLoading} size={150} />
      </div>
    );
  }

  return (
    <>
      <SEO
        title={`${podcast.title} Notes | PodScribe.IO`}
        description={`Detailed notes and transcripts for episodes of "${podcast.title}". Discover insights and summaries from this podcast on PodScribe.IO.`}
        type="website"
        url={`https://podscribe.io/content/podcasts/${podcastId}/episodes`}
        image={podcast.image}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://podscribe.io/content/podcasts/${podcastId}/episodes`,
            },
            headline: `${podcast.title} Notes | PodScribe.IO`,
            description: `Detailed notes and transcripts for episodes of "${podcast.title}". Discover insights and summaries from this podcast on PodScribe.IO.`,
            image: podcast.image,
            publisher: {
              "@type": "Organization",
              name: "PodScribe.IO",
              logo: {
                "@type": "ImageObject",
                url: "https://podscribe.io/og-image.jpg",
              },
            },
          })}
        </script>
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <PodcastHeader podcast={podcast} />
        <h2 className="text-2xl font-bold text-primary-800 mb-6">Episodes</h2>
        <EpisodeList episodes={episodes} podcastId={podcastId} />
      </div>
    </>
  );
};

export default PodcastEpisodes;
