import React from 'react';

export default function YouTubeInput({ youtubeLink, setYoutubeLink }) {
  return (
    <div>
      <label htmlFor="youtube-link" className="block text-sm font-medium text-gray-700">
        YouTube Link
      </label>
      <div className="mt-1">
        <input
          type="text"
          name="youtube-link"
          id="youtube-link"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="https://www.youtube.com/watch?v=..."
          value={youtubeLink}
          onChange={(e) => setYoutubeLink(e.target.value)}
        />
      </div>
      <p className="mt-2 text-sm text-gray-500">
        Paste the YouTube video link here. Make sure you have the rights to use this content.
      </p>
    </div>
  );
}
