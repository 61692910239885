import React, { useEffect, useReducer, useContext, useRef } from "react";
import { axiosPrivate } from "../../../common/axiosPrivate";
import LoadingSpinner from '../../common/LoadingSpinner';
import Button from "../../../UI/Button";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import PodcastProfileHeadingCard from "../../../UI/PodcastProfileHeadingCard";
import PodcastEpisodeCard from "../../../UI/PodcastEpisodeCard";
import LibraryDataContext from "../../../context/LibraryDataProvider";

const FETCH_EPISODES = "FETCH_EPISODES";
const CHANGE_EPISODES_PAGE = "CHANGE_EPISODES_PAGE";

const initialState = {
  episodes: [],
  episodesCurrentPage: 1,
  episodesTotalPages: 1,
  episodesItemsPerPage: 10,
  episodesTotalItems: 0,
  isLoading: false,
};

const episodesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_EPISODES:
      return {
        ...state,
        episodes: action.payload.episodes,
        episodesCurrentPage: action.payload.page,
        episodesTotalPages: action.payload.pages,
        episodesItemsPerPage: action.payload.size,
        episodesTotalItems: action.payload.total,
        isLoading: false,
      };
    case CHANGE_EPISODES_PAGE:
      return {
        ...state,
        episodesCurrentPage: action.payload,
        isLoading: true,
      };
    case "UPDATE_EPISODE":
      return {
        ...state,
        episodes: state.episodes.map((episode) =>
          episode.id === action.payload.episodeId
            ? { ...episode, is_added_to_library: action.payload.isAdded }
            : episode
        ),
      };
    default:
      return state;
  }
};

export default function PodcastEpisodes({ podcast, onBack, handleFavoriteOnClick, onClose }) {
  const [state, dispatch] = useReducer(episodesReducer, initialState);
  const { addLibraryEpisode, removeLibraryEpisode } = useContext(LibraryDataContext);
  const episodesContainerRef = useRef(null);

  useEffect(() => {
    const fetchEpisodes = async () => {
      dispatch({ type: CHANGE_EPISODES_PAGE, payload: 1 });
      try {
        const response = await axiosPrivate.get(
          `/podcast/${podcast.id}/episodes?page=1&size=${state.episodesItemsPerPage}`
        );
        dispatch({
          type: FETCH_EPISODES,
          payload: {
            episodes: response.data.items,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          },
        });
      } catch (error) {
        console.error("Error fetching episodes: ", error);
      }
    };
    fetchEpisodes();
  }, [podcast.id, state.episodesItemsPerPage]);

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= state.episodesTotalPages) {
      dispatch({ type: CHANGE_EPISODES_PAGE, payload: newPage });
      try {
        const response = await axiosPrivate.get(`/podcast/${podcast.id}/episodes`, {
          params: {
            page: newPage,
            size: state.episodesItemsPerPage,
          },
        });
        dispatch({
          type: FETCH_EPISODES,
          payload: {
            episodes: response.data.items,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          },
        });
        episodesContainerRef.current?.scrollIntoView();
      } catch (error) {
        console.error("Error fetching episodes: ", error);
      }
    }
  };

  const handleAddRemove = async (feedId, episodeId, isAdded) => {
    if (isAdded) {
      await addLibraryEpisode(feedId, episodeId);
    } else {
      await removeLibraryEpisode(feedId, episodeId);
    }
    dispatch({ type: "UPDATE_EPISODE", payload: { episodeId, isAdded } });
  };

  return (
    <div className="h-full overflow-y-auto">
      <Button onClick={onBack} className="mb-4">
        <ArrowLeftIcon className="h-5 w-5 mr-2" />
        Back to Search Results
      </Button>
      <PodcastProfileHeadingCard feed={podcast} handleFavorite={handleFavoriteOnClick} />
      <h2 ref={episodesContainerRef} className="text-xl font-bold text-left text-primary-600 mt-4">
        Episodes
      </h2>
      {state.isLoading && state.episodes.length === 0 ? (
        <LoadingSpinner />
      ) : (
        <>
          {state.episodes.map((episode, index) => (
            <PodcastEpisodeCard
              key={index}
              episode={episode}
              handleAddRemove={handleAddRemove}
              onCloseModal={onClose}
            />
          ))}
          <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6">
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">{(state.episodesCurrentPage - 1) * state.episodesItemsPerPage + 1}</span>{" "}
                to{" "}
                <span className="font-medium">
                  {Math.min(state.episodesCurrentPage * state.episodesItemsPerPage, state.episodesTotalItems)}
                </span>{" "}
                of <span className="font-medium">{state.episodesTotalItems}</span> results
              </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
              <button
                onClick={() => handlePageChange(state.episodesCurrentPage - 1)}
                disabled={state.isLoading || state.episodesCurrentPage === 1}
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1
                ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
                Previous
              </button>
              <button
                onClick={() => handlePageChange(state.episodesCurrentPage + 1)}
                disabled={state.isLoading || state.episodesCurrentPage === state.episodesTotalPages}
                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1
                ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
                Next
              </button>
            </div>
          </nav>
        </>
      )}
    </div>
  );
}
