import React from "react";
import { SparklesIcon } from '@heroicons/react/solid';

const PartnerBanners = () => {
  return (
    <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
      <a
        href="https://theresanaiforthat.com/ai/podscribe-1721038783/?ref=featured&v=2464322"
        target="_blank"
        rel="nofollow noopener noreferrer"
        className="inline-block w-[150px] h-[40px]">
        <img
          src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=250"
          alt="Featured on There's an AI for That"
          className="w-full h-full object-contain"
        />
      </a>
      <a
        href="https://deepgram.com/ai-apps/podscribe-io"
        target="_blank"
        rel="nofollow noopener noreferrer"
        className="inline-flex items-center justify-center w-[150px] h-[40px] bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-bold text-xs px-2 rounded-full hover:from-purple-700 hover:to-indigo-700 transition duration-300 shadow-md text-center">
        <SparklesIcon className="h-4 w-4 mr-1" /> Featured on Deepgram
      </a>
    </div>
  );
};

export default PartnerBanners;
