import { useCallback, useEffect, useReducer } from "react";
import { axiosPrivate } from "../common/axiosPrivate";

let isCalled = false;

const initialHttpState = {
  data: null,
  isLoading: false,
  error: null,
};

function httpReducer(state, action) {
  if (action.type === "FETCH_START") {
    return {
      ...state,
      isLoading: state.data ? false : true,
      error: null,
    };
  }

  if (action.type === "FETCH_ERROR") {
    return {
      data: null,
      isLoading: false,
      error: action.payload,
    };
  }

  if (action.type === "FETCH_SUCCESS") {
    return {
      data: action.payload,
      isLoading: false,
      error: null,
    };
  }

  return initialHttpState;
}

function useFetch(url) {
  const [httpState, dispatch] = useReducer(httpReducer, initialHttpState);

  const fetchData = useCallback(
    async function fetchData() {
      dispatch({ type: "FETCH_START" });
      const response = axiosPrivate
        .get(url)
        .then((response) => {
          dispatch({ type: "FETCH_SUCCESS", payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: "FETCH_ERROR", payload: error.message });
        });
    },
    [url],
  );

  useEffect(
    function () {
      if (isCalled) return;
      fetchData();
      isCalled = true;
    },
    [fetchData],
  );

  return [httpState, fetchData];
}

export default useFetch;
