import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { PencilAltIcon, DocumentTextIcon, PencilIcon, ClockIcon } from "@heroicons/react/solid";

export default function TimestampNoteModal({
  show,
  onClose,
  onSave,
  timestamp,
  transcriptText,
  paragraphStart,
  paragraphEnd,
}) {
  const [note, setNote] = React.useState("");

  const handleSave = () => {
    onSave({
      timestamp,
      note,
      transcriptText,
    });
    setNote("");
  };

  const formatTimestamp = (seconds) => {
    return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <div className="border-b border-primary-100 pb-4">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold leading-6 text-primary-800 flex items-center mb-2">
                        <PencilAltIcon className="h-7 w-7 text-primary-600 mr-3" />
                        Add Audio Highlight at {formatTimestamp(timestamp)}
                      </Dialog.Title>
                    </div>
                    <div className="mt-6">
                      <div className="space-y-6">
                        {/* Transcript text */}
                        <div className="rounded-xl bg-white px-4 py-3">
                          <div className="flex items-center justify-between mb-3">
                            <h4 className="text-sm font-semibold text-secondary-700 flex items-center">
                              <DocumentTextIcon className="h-5 w-5 mr-2 text-secondary-500" />
                              Transcript Segment
                            </h4>
                            <span className="text-sm text-gray-500">
                              {formatTimestamp(paragraphStart)} - {formatTimestamp(paragraphEnd)}
                            </span>
                          </div>
                          <div className="prose prose-sm max-w-none text-gray-700 bg-secondary-50 p-3 rounded-lg">
                            {transcriptText && transcriptText.sentences ? (
                              transcriptText.sentences.map((sentence, index) => (
                                <p key={index} className="mb-2 last:mb-0">
                                  {sentence.text}
                                </p>
                              ))
                            ) : (
                              <div className="flex items-center justify-center text-gray-500 italic">
                                No transcript segment available at this timestamp
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Notes section */}
                        <div className="px-4 py-1 space-y-3">
                          <label
                            htmlFor="note"
                            className="block text-sm font-semibold text-primary-700 flex items-center">
                            <PencilIcon className="h-5 w-5 mr-2 text-primary-500" />
                            Your Notes
                          </label>
                          <textarea
                            id="note"
                            rows={4}
                            className="block w-full rounded-xl border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-primary-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6 transition duration-200"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Add your thoughts about this segment... (Markdown supported)"
                          />
                          <p className="mt-2 text-sm text-neutral-500 italic">Your note will be added to the Notes tab</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                    onClick={handleSave}>
                    Save and Play
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
