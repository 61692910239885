import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import * as OutlineIcons from "@heroicons/react/outline";
import * as SolidIcons from "@heroicons/react/solid";
import useLibraryData from "../../hooks/useLibraryData";
import { toast } from "react-hot-toast";

export default function FolderSelectionModal({ show, onClose, folders, episodeId }) {
  const { addEpisodeToFolder, removeEpisodeFromFolder } = useLibraryData();
  
  const handleToggleFolder = async (folder) => {
    try {
      const isEpisodeInFolder = folder.episodes?.includes(episodeId);
      if (isEpisodeInFolder) {
        await removeEpisodeFromFolder(folder.id, episodeId);
        toast.success("Episode removed from folder");
      } else {
        await addEpisodeToFolder(folder.id, episodeId);
        toast.success("Episode added to folder");
      }
    } catch (error) {
      console.error("Error updating folder membership:", error);
      toast.error("Failed to update folder");
    }
  };
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-neutral-500/25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-neutral-900">
                    Select Folder
                  </Dialog.Title>
                  <p className="mt-2 text-sm text-neutral-500">
                    Choose a folder to organize your content. You can move items between folders at any time.
                  </p>
                  <div className="mt-2 space-y-2">
                    {folders.length === 0 ? (
                      <div className="text-center py-6">
                        <p className="text-neutral-500">No folders available</p>
                        <p className="text-sm text-neutral-400 mt-1">Create a folder first to organize your episodes</p>
                      </div>
                    ) : (
                      folders.map((folder) => (
                        <div
                          key={folder.id}
                          className="w-full px-4 py-2 rounded-md text-sm flex items-center justify-between group hover:bg-neutral-50">
                          <div className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              checked={folder.episodes?.includes(episodeId)}
                              onChange={() => handleToggleFolder(folder)}
                              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300 rounded"
                            />
                            {folder.icon ? (
                              React.createElement(
                                OutlineIcons[folder.icon] || SolidIcons[folder.icon] || OutlineIcons.FolderIcon,
                                { className: "h-5 w-5 text-neutral-400" }
                              )
                            ) : (
                              <OutlineIcons.FolderIcon className="h-5 w-5 text-neutral-400" />
                            )}
                            <span>{folder.name}</span>
                          </div>
                          <span className="text-xs text-neutral-400 group-hover:text-neutral-600">
                            {folder.episodes?.length || 0} items
                          </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
