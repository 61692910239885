import { createContext, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { axiosPrivate } from "../common/axiosPrivate";
import { toast } from "react-hot-toast";

const LibraryDataContext = createContext({
  podcasts: [],
  episodes: [],
  notes: [],
  loading: false,
  error: null,
  episodeCount: 0,
});

export const LibraryDataProvider = ({ children }) => {
  const { auth } = useAuth();
  const [LibraryData, setLibraryData] = useState({
    podcasts: [],
    episodes: [],
    notes: [],
    folders: [], // Each folder should have {id, name, episodes: []}
    loading: true,
    error: null,
    episodeCount: 0,
  });

  const startEpisodePolling = (
    feedId,
    episodeId,
    interval = 5000,
    duration = 60000,
  ) => {
    const pollId = setInterval(async () => {
      const updatedEpisode = await refreshLibraryEpisode(feedId, episodeId);
      if (!updatedEpisode.is_processing) {
        clearInterval(pollId);
      }
    }, interval);

    // Cancel polling after the specified duration
    setTimeout(() => {
      clearInterval(pollId);
    }, duration);

    return pollId;
  };

  const refreshLibrary = async () => {
    setLibraryData((prevData) => ({ ...prevData, loading: true }));
    try {
      const [response, countResponse, foldersResponse] = await Promise.all([
        axiosPrivate.get("/library/"),
        axiosPrivate.get("/library/count"),
        axiosPrivate.get("/folders/"),
      ]);
      setLibraryData({
        episodes: response.data,
        folders: foldersResponse.data.map((folder) => ({
          ...folder,
          episodes: folder.episodes || [],
        })),
        loading: false,
        error: null,
        episodeCount: countResponse.data.count,
      });
    } catch (error) {
      console.log(error);
      setLibraryData((prevData) => ({
        ...prevData,
        loading: false,
        error: error,
      }));
    }
  };

  const fetchEpisodeCount = async () => {
    try {
      const response = await axiosPrivate.get("/library/count");
      setLibraryData((prevData) => ({
        ...prevData,
        episodeCount: response.data.count,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const createFolder = async (folderData) => {
    try {
      const response = await axiosPrivate.post("/folders/", folderData);
      const newFolder = {
        ...response.data,
        episodes: [], // Initialize empty episodes array
      };
      setLibraryData((prevData) => ({
        ...prevData,
        folders: [...(prevData.folders || []), newFolder],
      }));
      return newFolder;
    } catch (error) {
      console.error("Error creating folder:", error);
      throw error;
    }
  };

  const updateFolder = async (folderId, folderData) => {
    try {
      const response = await axiosPrivate.put(
        `/folders/${folderId}`,
        folderData,
      );
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.map((folder) =>
          folder.id === folderId
            ? { ...response.data, episodes: folder.episodes }
            : folder,
        ),
      }));
      return response.data;
    } catch (error) {
      console.error("Error updating folder:", error);
      throw error;
    }
  };

  const deleteFolder = async (folderId) => {
    try {
      await axiosPrivate.delete(`/folders/${folderId}`);
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.filter((folder) => folder.id !== folderId),
      }));
    } catch (error) {
      console.error("Error deleting folder:", error);
      throw error;
    }
  };

  const addEpisodeToFolder = async (folderId, episodeId) => {
    try {
      const response = await axiosPrivate.post(
        `/folders/${folderId}/episodes/${episodeId}`,
      );
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.map((folder) => {
          if (folder.id === folderId) {
            return {
              ...folder,
              episodes: [...folder.episodes, episodeId],
            };
          }
          return folder;
        }),
      }));
      return response.data;
    } catch (error) {
      console.error("Error adding episode to folder:", error);
      throw error;
    }
  };

  const removeEpisodeFromFolder = async (folderId, episodeId) => {
    try {
      const response = await axiosPrivate.delete(
        `/folders/${folderId}/episodes/${episodeId}`,
      );
      setLibraryData((prevData) => ({
        ...prevData,
        folders: prevData.folders.map((folder) => {
          if (folder.id === folderId) {
            return {
              ...folder,
              episodes: folder.episodes.filter((id) => id !== episodeId),
            };
          }
          return folder;
        }),
      }));
      return response.data;
    } catch (error) {
      console.error("Error removing episode from folder:", error);
      throw error;
    }
  };
  // fetch the data from the backend
  useEffect(() => {
    if (!auth.token) {
      return;
    }
    const fetchLibraryData = async () => {
      setLibraryData((prevData) => ({ ...prevData, loading: true }));
      try {
        const [response, foldersResponse] = await Promise.all([
          axiosPrivate.get("/library/"),
          axiosPrivate.get("/folders/"),
        ]);
        console.log("Fetched Library Data: ", response.data);
        console.log("Fetched Library Folders: ", foldersResponse.data);
        setLibraryData({
          episodes: response.data,
          folders: foldersResponse.data.map((folder) => ({
            ...folder,
            episodes: folder.episodes || [],
          })),
          loading: false,
          error: null,
        });
        console.log("Library Data after setting: ", LibraryData);
      } catch (error) {
        console.log(error);
        setLibraryData((prevData) => ({
          ...prevData,
          loading: false,
          error: error,
        }));
      }
    };

    fetchLibraryData();
  }, [auth.token]);

  const refreshLibraryEpisode = async (feedId, episodeId) => {
    try {
      const response = await axiosPrivate.get(
        `/library/podcast/${feedId}/${episodeId}`,
      );
      const updatedEpisode = response.data;
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.map((episode) =>
          episode.id === episodeId ? updatedEpisode : episode,
        ),
        loading: false,
        error: null,
      }));
      return updatedEpisode;
    } catch (error) {
      console.log(error);
      setLibraryData((prevData) => ({ ...prevData, loading: false }));
      return null;
    }
  };

  const addLibraryEpisode = async (feedId, episodeId) => {
    try {
      console.log("Adding episode to library: ", feedId, episodeId);
      const response = await axiosPrivate.post(
        `/library/${feedId}/${episodeId}`,
      );
      console.log(response.data);
      const newEpisode = {
        ...response.data,
        isNew: true
      };
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: [...prevData.episodes, newEpisode],
      }));
    } catch (error) {
      // if the error is because the episode is already in the library, just refresh the episode
      if (error.response && error.response.status === 409) {
        refreshLibraryEpisode(feedId, episodeId);
        toast.success("Episode already in library");
        return;
      }

      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  // add removeLibraryEpisode function
  const toggleStarEpisode = async (episodeId) => {
    try {
      const episode = LibraryData.episodes.find((ep) => ep.id === episodeId);
      const isCurrentlyStarred = episode?.is_starred || false;

      if (isCurrentlyStarred) {
        await axiosPrivate.delete(`/folders/star/${episodeId}`);
      } else {
        await axiosPrivate.post(`/folders/star/${episodeId}`);
      }

      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.map((episode) =>
          episode.id === episodeId
            ? { ...episode, is_starred: !isCurrentlyStarred }
            : episode,
        ),
      }));

      toast.success(
        isCurrentlyStarred ? "Episode unstarred" : "Episode starred",
      );
    } catch (error) {
      console.error("Error toggling star status:", error);
      toast.error("Failed to update star status");
    }
  };

  const removeLibraryEpisode = async (feedId, episodeId) => {
    try {
      await axiosPrivate.delete(`/library/${feedId}/${episodeId}`);
      setLibraryData((prevData) => ({
        ...prevData,
        episodes: prevData.episodes.filter(
          (episode) => episode.id !== episodeId,
        ),
        // Also remove the episode from all folders
        folders: prevData.folders.map((folder) => ({
          ...folder,
          episodes: folder.episodes.filter((id) => id !== episodeId),
        })),
      }));
      toast.success("Episode removed from library");
    } catch (error) {
      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  return (
    <LibraryDataContext.Provider
      value={{
        LibraryData,
        setLibraryData,
        refreshLibraryEpisode,
        addLibraryEpisode,
        refreshLibrary,
        removeLibraryEpisode,
        startEpisodePolling,
        fetchEpisodeCount,
        createFolder,
        updateFolder,
        deleteFolder,
        addEpisodeToFolder,
        removeEpisodeFromFolder,
        toggleStarEpisode,
      }}
    >
      {children}
    </LibraryDataContext.Provider>
  );
};

export default LibraryDataContext;
