import { Fragment, useState } from "react";
import useLibraryData from "../../hooks/useLibraryData";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import Img from "../../UI/Img";
import { SearchIcon } from "@heroicons/react/solid";
import { remove_html_tags } from "../../common/utils";
import { Highlight, HighlightWithoutContext, HighlightWithoutContextAndReturnOnNoMatch } from "../../common/utils";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/outline";
import { useDebounceValue } from "usehooks-ts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Search({ openSearchModal, setOpenSearchModal, SelectEpisodeHandler }) {
  const [query, setQuery] = useState("");
  const { LibraryData, SetLibraryData } = useLibraryData();

  const episode_list = LibraryData.episodes;
  const searchTerm = query;

  function includesIgnoreCase(str, searchTerm) {
    return str.toLowerCase().includes(searchTerm.toLowerCase());
  }

  function searchEpisodes(episode_list, searchTerm) {
    if (searchTerm === "") {
      return [];
    } else {
      return episode_list.filter((episode) => {
        if (includesIgnoreCase(episode.title, searchTerm)) return true;
        if (includesIgnoreCase(episode.description, searchTerm)) return true;
        if (includesIgnoreCase(episode.podcast_title, searchTerm)) return true;

        const isSummarized = episode.is_summarized && episode.summary;
        if (isSummarized) {
          if (includesIgnoreCase(episode.summary.episode_summary.summary, searchTerm)) return true;
          if (
            episode.summary.chapters &&
            episode.summary.chapters.some((chapter) => includesIgnoreCase(chapter.combined_key_takeaway, searchTerm))
          ) {
            return true;
          }
        }

        if (
          episode.is_transcribed &&
          episode.transcription &&
          includesIgnoreCase(episode.transcription.text, searchTerm)
        ) {
          return true;
        }

        return false;
      });
    }
  }

  const filteredItems = searchEpisodes(episode_list, searchTerm);

  return (
    <Transition.Root show={openSearchModal} as={Fragment} afterLeave={() => setQuery("")} appear>
      <Dialog as="div" className="relative z-10" onClose={setOpenSearchModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(item) => {
                  SelectEpisodeHandler(item.id);
                  setOpenSearchModal(false);
                }}>
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => {
                      setQuery(event.target.value);
                    }}
                  />
                  {query && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <XIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => setQuery("")}
                      />
                    </div>
                  )}
                </div>
                {filteredItems.length > 0 && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {filteredItems.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          classNames("flex cursor-default select-none rounded-xl p-3", active && "bg-gray-100")
                        }>
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                "flex h-10 w-10 flex-none items-center justify-center rounded-lg",
                                item.title
                              )}>
                              <Img src={item.image} className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className="ml-4 flex-auto">
                              <p
                                className={classNames(
                                  "text-sm font-medium",
                                  active ? "text-gray-900" : "text-gray-700"
                                )}>
                                <HighlightWithoutContextAndReturnOnNoMatch
                                  text={item.podcast_title}
                                  highlight={searchTerm}
                                  className="text-lg font-medium text-primary-600"
                                />
                              </p>
                              <p className={classNames("text-sm", active ? "text-gray-700" : "text-gray-500")}>
                                <HighlightWithoutContextAndReturnOnNoMatch
                                  text={item.title}
                                  highlight={searchTerm}
                                  className="text-sm font-medium text-secondary-600 line-clamp-2"
                                />
                              </p>
                              <p className="text-sm mt-1 text-primary-600">Description:</p>
                              <p className={classNames("text-sm", active ? "text-gray-700" : "text-gray-500")}>
                                <Highlight
                                  text={remove_html_tags(item.description)}
                                  highlight={searchTerm}
                                  className="text-sx font-medium text-neutral-60 line-clamp-2"
                                />
                              </p>
                              {item.summary && item.summary.episode_summary && (
                                <div className=" ">
                                  <p className="text-sm mt-1 text-primary-600">Summary:</p>
                                  <p className={classNames("text-sm", active ? "text-gray-700" : "text-gray-500")}>
                                    <Highlight
                                      text={item.summary.episode_summary.summary}
                                      highlight={searchTerm}
                                      className="text-sm font-medium text-neutral-60 border-neutral-60 line-clamp-2"
                                    />
                                  </p>
                                  <p className="text-sm mt-1 text-primary-600">Key Takeaways:</p>
                                  <p className={classNames("text-sm", active ? "text-gray-700" : "text-gray-500")}>
                                    <Highlight
                                      text={item.summary.chapters
                                        .map((chapter) => chapter.combined_key_takeaway)
                                        .join("\n")}
                                      highlight={searchTerm}
                                      className="text-sm font-medium text-neutral-60 border-neutral-60"
                                    />
                                  </p>
                                </div>
                              )}
                              {item.transcription && item.transcription.text && (
                                <div className="">
                                  <p className="text-sm text-primary-600">Transcript:</p>
                                  <p className={classNames("text-sm", active ? "text-gray-700" : "text-gray-500")}>
                                    <Highlight
                                      text={item.transcription.text}
                                      highlight={searchTerm}
                                      context={100}
                                      className="text-sm font-medium text-neutral-60 border-neutral-60 line-clamp-2"
                                    />
                                  </p>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
                {query !== "" && filteredItems.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">No results found</p>
                    <p className="mt-2 text-gray-500">No components found for this search term. Please try again.</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
