import { memoizedRefreshToken } from "../common/refreshToken";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const Refresh = () => {
  const [refreshToken, setRefreshToken] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    const memoizedRefresh = memoizedRefreshToken();
    setRefreshToken(memoizedRefresh);
  }, []);

  if (refreshToken) {
    navigate("/library", { replace: true });
  }

  navigate("/library", { replace: true });
};

export default Refresh;
