import React from "react";
import { Helmet } from "react-helmet-async";

export default function SEO({
  title = "PodScribe.IO - Transform Podcasts into Actionable Knowledge",
  description = "PodScribe.IO converts podcasts into searchable knowledge, making it easy to access and digest content for educational and inspirational benefits.",
  type = "website",
  name = "PodScribe.IO",
  url = "https://podscribe.io/",
  image = "https://podscribe.io/og-image.jpg",
  keyword = "Podcast Transcription, Podcast Summaries, Audio to Text, Podcast Highlights, Podcast Notes, Podcast Knowledge Base, Searchable Podcast Content, Podcast Insights, Podcast Discovery Tool, NLP for Podcasts, Podcast Information Extraction, Podcast Content Analysis, Podcast Learning Tool, Podcast Digest, Podcast Episode Summaries, Podcast Recall Tool, Podcast Organization, Podcast Data Repository, Podcast Subscription Service, Podcast Educational Tool",
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content={name} />
      <meta name="keywords" content={keyword} />

      {/* OpenGraph tags */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Additional tags */}
      <meta name="apple-mobile-web-app-title" content={name} />
      <meta name="application-name" content={name} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
}
