import React, { useEffect, useState, useReducer, useContext, useRef, useMemo } from "react";
import { axiosPrivate } from "../../../common/axiosPrivate";
import LoadingSpinner from '../../common/LoadingSpinner';
import { useDebounceValue } from "usehooks-ts";
import SearchInput from "../../../UI/SearchComponent";
import PodcastEpisodeCard from "../../../UI/PodcastEpisodeCard";
import LibraryDataContext from "../../../context/LibraryDataProvider";

const FETCH_DATA = "FETCH_DATA";
const CHANGE_PAGE = "CHANGE_PAGE";
const SET_SEARCH_TERM = "SET_SEARCH_TERM";

const initialState = {
  episodes: [],
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 5,
  totalPages: 1,
  isLoading: true,
  searchTerm: "",
};

const episodesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        episodes: action.payload.items,
        totalItems: action.payload.total,
        currentPage: action.payload.page,
        itemsPerPage: action.payload.size,
        totalPages: action.payload.pages,
        isLoading: false,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        isLoading: true,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
        currentPage: 1,
        isLoading: true,
      };
    case "UPDATE_EPISODE":
      return {
        ...state,
        episodes: state.episodes.map((episode) =>
          episode.id === action.payload.episodeId
            ? { ...episode, is_added_to_library: action.payload.isAdded }
            : episode
        ),
      };
    default:
      return state;
  }
};

export default function ProcessedTab({ onClose }) {
  const [state, dispatch] = useReducer(episodesReducer, initialState);
  const [searchValue, setSearchValue] = useState("");
  const [cache, setCache] = useState(new Map());
  const CACHE_KEY_PREFIX = useMemo(() => 'processed-episodes', []);
  const [debouncedSearchValue] = useDebounceValue(searchValue, 500);
  const { addLibraryEpisode, removeLibraryEpisode } = useContext(LibraryDataContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cacheKey = `${CACHE_KEY_PREFIX}-${state.currentPage}-${state.searchTerm}`;
        
        if (cache.has(cacheKey)) {
          dispatch({ type: FETCH_DATA, payload: cache.get(cacheKey) });
          setTimeout(() => {
            episodesContainerRef.current?.scrollIntoView();
          }, 0);
          return;
        }

        const response = await axiosPrivate.get(`/repository/episodes/processed`, {
          params: {
            page: state.currentPage,
            size: state.itemsPerPage,
            search: state.searchTerm,
          },
        });
        
        cache.set(cacheKey, response.data);
        if (cache.size > 20) {
          const firstKey = cache.keys().next().value;
          cache.delete(firstKey);
        }
        
        dispatch({ type: FETCH_DATA, payload: response.data });
        episodesContainerRef.current?.scrollIntoView();
      } catch (error) {
        console.error("Error fetching processed episodes: ", error);
        dispatch({ 
          type: FETCH_DATA, 
          payload: { 
            items: [], 
            total: 0, 
            page: 1, 
            size: state.itemsPerPage, 
            pages: 1 
          } 
        });
      }
    };
    fetchData();
  }, [state.currentPage, state.itemsPerPage, state.searchTerm]);

  const episodesContainerRef = useRef(null);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= state.totalPages) {
      dispatch({ type: CHANGE_PAGE, payload: newPage });
      episodesContainerRef.current?.scrollIntoView();
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearchValue !== state.searchTerm) {
      dispatch({ type: SET_SEARCH_TERM, payload: debouncedSearchValue });
      setCache(new Map()); // Clear cache when search term changes
    }
  }, [debouncedSearchValue]);

  const handleAddRemove = async (feedId, episodeId, isAdded) => {
    if (isAdded) {
      await addLibraryEpisode(feedId, episodeId);
    } else {
      await removeLibraryEpisode(feedId, episodeId);
    }
    dispatch({ type: "UPDATE_EPISODE", payload: { episodeId, isAdded } });
  };

  return (
    <div className="h-full overflow-y-auto px-4 sm:px-6 lg:px-8 relative">
      <div className="max-w-3xl mx-auto">
        <SearchInput
          value={searchValue}
          onChange={handleSearch}
          className="mb-6 w-full"
          placeholder="Search processed episodes..."
        />
        {state.isLoading && state.episodes.length === 0 && (
          <LoadingSpinner />
        )}
        {!state.isLoading && state.episodes.length === 0 && (
          <div className="text-center mt-8">
            <h3 className="text-lg text-neutral-400">No processed episodes found.</h3>
          </div>
        )}
        <h2 ref={episodesContainerRef} className="text-xl font-bold text-left text-primary-600 mb-4">Episodes</h2>
        <div className="grid gap-6">
          {state.episodes.map((episode, index) => (
            <PodcastEpisodeCard
              key={index}
              episode={episode}
              handleAddRemove={handleAddRemove}
              onCloseModal={onClose}
            />
          ))}
        </div>
        <nav
          aria-label="Pagination"
          className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-6">
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{(state.currentPage - 1) * state.itemsPerPage + 1}</span> to{" "}
              <span className="font-medium">{Math.min(state.currentPage * state.itemsPerPage, state.totalItems)}</span>{" "}
              of <span className="font-medium">{state.totalItems}</span> results
            </p>
          </div>
          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={() => handlePageChange(state.currentPage - 1)}
              disabled={state.isLoading || state.currentPage === 1}
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              Previous
            </button>
            <button
              onClick={() => handlePageChange(state.currentPage + 1)}
              disabled={state.isLoading || state.currentPage === state.totalPages}
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
              Next
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}
