import { XIcon } from "@heroicons/react/solid";

const SearchInput = ({
  value,
  onChange,
  placeholder = "Filter...",
  className = "",
}) => {
  return (
    <div className="relative mt-2 rounded-md shadow-sm">
      <input
        type="text"
        onChange={onChange}
        value={value}
        className={`focus:ring-primary-500 focus:border-primary-500 w-32 rounded-lg pl-3 block sm:text-sm border-neutral-300 ${className}`}
        placeholder={placeholder}
      />
      {value && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-40">
          <XIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
            onClick={(e) => onChange({ target: { value: "" } })}
          />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
