import React from "react";
import { Panel, useReactFlow, getNodesBounds, getViewportForBounds } from "reactflow";
import { toPng } from "html-to-image";
import { DownloadIcon } from "@heroicons/react/outline";

function downloadImage(dataUrl) {
  const a = document.createElement("a");

  a.setAttribute("download", "reactflow.png");
  a.setAttribute("href", dataUrl);
  a.click();
}

const imageWidth = 8000;
const imageHeight = 6000;

function DownloadButton() {
  const { getNodes } = useReactFlow();
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(getNodes());
    const viewport = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#fff",
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
      },
    }).then(downloadImage);
  };

  return (
    <Panel position="top-right">
      <button className="text-primary-600 p-3 bg-primary-100 rounded-md" onClick={onClick}>
        <DownloadIcon className="w-6 h-6" />
      </button>
    </Panel>
  );
}

export default DownloadButton;
