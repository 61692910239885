import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import PodcastIcon from "../../UI/PodcastIcon";

import { Button } from "./Button";
import { Container } from "./Container";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import useAuth from "../../hooks/useAuth";

function MobileNavLink({ href, children }) {
  return (
    <NavLink as={Link} href={href} className="block w-full p-2">
      {children}
    </NavLink>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round">
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={classNames("origin-center transition", open && "scale-90 opacity-0")}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={classNames("origin-center transition", !open && "scale-90 opacity-0")}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center focus:outline-none"
        aria-label="Toggle Navigation">
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-primary-900 shadow-xl ring-1 ring-slate-900/5">
            <MobileNavLink href="/#features">Features</MobileNavLink>
            <MobileNavLink href="/#pricing">Pricing</MobileNavLink>
            <MobileNavLink href="/#faq">FAQ</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/content/podcasts">Content</MobileNavLink>
            <MobileNavLink href="/blog">Blog</MobileNavLink>
            <MobileNavLink href="/login">Sign in</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header(props) {
  const onlyLogo = props.onlyLogo;
  const { auth } = useAuth();

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <div className="flex items-center justify-center">
                <PodcastIcon className="h-6 w-6 fill-primary-600" />
                <h1 className="ml-2 text-xl font-bold text-primary-900 bg-gradient-to-r from-primary-600 to-primary-900 bg-clip-text text-transparent">
                  PodScribe.IO
                </h1>
              </div>
            </Link>
            {!onlyLogo && (
              <div className="hidden md:block">
                <NavLink href="/#features">Features</NavLink>
                <NavLink href="/#pricing">Pricing</NavLink>
                <NavLink href="/#faq">FAQ</NavLink>
              </div>
            )}
          </div>
          <div className="flex items-center gap-x-1 md:gap-x-3">
            <div className="hidden md:block">
              <NavLink href="/content/podcasts">Content</NavLink>
              <NavLink href="/blog">Blog</NavLink>
            </div>
            <div className="hidden md:block">{auth.token ? null : <NavLink href="/login">Sign in</NavLink>}</div>
            <Button href={auth.token ? "/library" : "/register"} color="">
              <span className="bg-primary-600 rounded-full p-2 text-white">
                {auth.token ? "Go to Inbox" : "Get started"}{" "}
                <span className="hidden lg:inline">{auth.token ? "" : "today"}</span>
              </span>
            </Button>
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
