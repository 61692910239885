// tracker.js
import Tracker from "@openreplay/tracker";

let trackerInstance = null;

export const getTracker = () => {
  if (!trackerInstance) {
    trackerInstance = new Tracker({
      projectKey: "9L6t2QxYKKysYw9w7O9f",
    });
    trackerInstance.start();
  }
  return trackerInstance;
};
