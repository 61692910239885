import React, { useEffect } from "react";

const PaddleScript = () => {
  useEffect(() => {
    if (!window.Paddle) {
      const script = document.createElement("script");
      const event = new CustomEvent("changeState", {
        detail: { newStateValue: true },
      });
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      script.async = true;

      script.onload = () => {
        console.log("On paddle load");
        if (process.env.REACT_APP_PADDLE_SANDBOX == "true") {
          window.Paddle.Environment.set("sandbox");
          console.log("Paddle sandbox mode");
        }
        window.Paddle.Setup({
          token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
          eventCallback: function (event_data) {
            if (event_data.name === "checkout.loaded") {
              console.log(event_data.data);
              window.dispatchEvent(event);
            } else if (event_data.name === "checkout.complete") {
              console.log(event_data.data);
              window.dispatchEvent(event);
            } else if (event_data.name === "checkout.close") {
              console.log(event_data.data);
            }
            console.log(event_data);
          },
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      console.log("Paddle already loaded");
    }
  }, []);

  return null;
};

export default PaddleScript;
