import mem from "mem";

import { axiosPublic } from "./axiosPublic";
import { Navigate } from "react-router-dom";

const refreshTokenFn = async () => {
  const loadedSession = JSON.parse(localStorage.getItem("session"));

  try {
    const response = await axiosPublic.get("/auth/refresh", {
      headers: {
        authorization: `Bearer ${loadedSession?.refresh_token}`,
      },
    });

    const session = {
      ...loadedSession,
      access_token: response.data.access_token,
    };

    localStorage.setItem("session", JSON.stringify(session));

    return session;
  } catch (error) {
    if (error.response?.status === 400) {
      console.log("Refresh token has expired, logging out...");
      localStorage.removeItem("session");
      localStorage.removeItem("user");
      window.location.href = "/login";
    } else {
      throw error;
    }
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
