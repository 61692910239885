import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import { PlusIcon, MinusIcon } from "@heroicons/react/solid";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@heroicons/react/outline"; // or '@heroicons/react/solid'

function CustomNode({ data }) {
  const [showBody, setShowBody] = useState(true);
  const [showCollapse, setShowCollapse] = useState(false);
  const handleToggle = (nodeId) => {
    data.handleToggle(nodeId);
    setShowCollapse(!showCollapse);
  };

  return (
    <div className="px-4 py-2 shadow-md rounded-md border-secondary-600 border max-w-lg bg-secondary-50">
      <div className="flex">
        <div className="ml-2">
          <div className="text-lg font-bold">
            <span className="w-6 h-6 pr-2">{data.emoji}</span>
            {data.title}
          </div>
          {showBody && <div className="text-neutral-600 mt-2 prose">{data.body}</div>}
          <button className="mt-2 text-primary-500 hover:text-primary-600" onClick={() => setShowBody(!showBody)}>
            {showBody ? "Less" : "More"}
          </button>
        </div>
      </div>

      <Handle type="target" position={Position.Left} className=" !bg-primary-500" />
      <Handle
        type="source"
        position={Position.Right}
        className=" !bg-primary-500 flex items-center justify-between"
        onClick={() => handleToggle(data.nodeId)}>
        <button className="rounded-full bg-primary-500 text-white">
          {showCollapse ? <MinusIcon className="h-5 w-5" /> : <PlusIcon className="h-5 w-5" />}
        </button>
      </Handle>
    </div>
  );
}

function CustomEpisodeNode({ data }) {
  const [showBody, setShowBody] = useState(true);
  return (
    <div className="px-4 py-2 shadow-md rounded-md border-primary-600 border bg-primary-50">
      <div className="flex flex-col">
        <div className="ml-2">
          <div className="text-lg font-bold">
            <span className="w-6 h-6 pr-2">{data.emoji}</span>
            {data.title}
          </div>
          {showBody && <div className="text-neutral-600 mt-2 prose">{data.body}</div>}
          <button className="mt-2 text-primary-500 hover:text-primary-600" onClick={() => setShowBody(!showBody)}>
            {showBody ? "Less" : "More"}
          </button>
        </div>
      </div>

      <Handle type="target" position={Position.Left} className=" !bg-primary-500" />
      <Handle type="source" position={Position.Right} className=" !bg-primary-500" />
    </div>
  );
}

function CustomPointNode({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const maxPage = Math.ceil(data.body.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data.body.slice(startIndex, endIndex);

  const nextPage = () => {
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="px-2 py-1 shadow-md rounded-md border-support-green-600 border max-w-lg bg-support-green-50">
      <div className="flex flex-col">
        <div className="text-lg font-bold">
          <span className="w-6 h-6 pr-2">{data.emoji}</span>
          {data.title}
        </div>
        <div className="ml-2">
          <div className="text-neutral-700 mt-2 prose text-xs">
            {Array.isArray(data.body) ? (
              <ul>
                {currentItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            ) : (
              data.body
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <button onClick={prevPage} className="disabled:opacity-50" disabled={currentPage === 1}>
          <ArrowNarrowLeftIcon className="h-5 w-5" />
        </button>
        <span className="text-neutral-900 font-semibold">
          Page {currentPage} of {maxPage}
        </span>
        <button onClick={nextPage} className="disabled:opacity-50" disabled={currentPage === maxPage}>
          <ArrowNarrowRightIcon className="h-5 w-5" />
        </button>
      </div>
      <Handle type="target" position={Position.Left} className=" !bg-primary-500" />
      <Handle type="source" position={Position.Right} className=" !bg-primary-500" />
    </div>
  );
}

export default memo(CustomNode);
export const MemoizedCustomPointNode = memo(CustomPointNode);
export const MemoizedCustomEpisodeNode = memo(CustomEpisodeNode);
