import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, { useNodesState, useEdgesState, MiniMap, Controls, Background, Panel } from "reactflow";
import { MemoizedCustomPointNode, MemoizedCustomEpisodeNode } from "./CustomNode";
import dagre from "dagre";

import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import DownloadButton from "./MindMapDownload";
const nodeTypes = {
  custom: CustomNode,
  customPoint: MemoizedCustomPointNode,
  customEpisode: MemoizedCustomEpisodeNode,
};
const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

function nodeColor(node) {
  switch (node.type) {
    case "custom":
      return "#FFD0B5";
    case "customEpisode":
      return "#4055A8";
    default:
      return "#0F8613";
  }
}

function MindMap(props) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [wasLoaded, setWasLoaded] = useState(false);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const nodeWidth = 800;
  const nodeHeight = 300;

  const getLayoutedElements = (nodes, edges, direction = "LR") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };

  const handleToggleChildCollapse = useCallback(
    (nodeId) => {
      // Find connected node IDs as before
      const connectedNodeIds = edges
        .filter((edge) => edge.source === nodeId || edge.target === nodeId)
        .map((edge) => (edge.source === nodeId ? edge.target : edge.source));

      console.log("connectedNodeIds", connectedNodeIds);

      setNodes((currentNodes) =>
        currentNodes.map((node) => {
          // Check if the node is connected and has a type of 'customPoint'
          if (connectedNodeIds.includes(node.id) && node.type === "customPoint") {
            // Toggle visibility
            const isCurrentlyHidden = node.hidden || false;
            return { ...node, hidden: !isCurrentlyHidden };
          }
          return node;
        })
      );
    },
    [setNodes, edges, props.selectedEpisodeState]
  );

  useEffect(() => {
    const { episode } = props.selectedEpisodeState;

    // Create a node for the episode title
    const episodeNode = {
      id: "episode",
      type: "customEpisode",
      data: { title: episode.title, body: episode.summary.episode_summary.summary, emoji: "📚" },
      position: { x: 0, y: 0 },
    };

    // Create a node for each chapter
    const chapterNodes = episode.summary.chapters.map((chapter, chapterIndex, chapterArray) => {
      return {
        id: `chapter${chapterIndex}`,
        type: "custom",
        position: { x: 0, y: 0 },
        data: {
          title: chapter.title,
          body: chapter.combined_key_takeaway,
          emoji: "📖",
          handleToggle: handleToggleChildCollapse,
          nodeId: `chapter${chapterIndex}`,
        },
      };
    });

    // Create an edge from the episode to each chapter
    const episodeEdges = chapterNodes.map((node, index) => ({
      id: `e-episode-${node.id}`,
      source: "episode",
      target: node.id,

      type: "straight",
      style: { stroke: "#2D3A8C" },
    }));
    // nice debug the edges and nodes
    // Create a node for each combined main point in each chapter
    const pointNodes = episode.summary.chapters.map((chapter, chapterIndex) => {
      // Combine all main points into a single string
      const combinedPoints = chapter.combined_main_points;

      // Create a single pointNode for the chapter
      return {
        id: `point-${chapterIndex}`,
        type: "customPoint",
        data: {
          title: `${chapter.title} notes`,
          body: combinedPoints,
          emoji: "📚",
        },
        position: { x: 0, y: 0 }, // Adjust position as needed
      };
    });

    // Assuming each chapter node has an id in the format `chapter-X`
    const pointEdges = pointNodes.map((node, index) => {
      return {
        id: `e-chapter${index}-point${index}`,
        source: `chapter${index}`,
        target: node.id,
        type: "straight",
        style: { stroke: "#18981D" },
      };
    });

    // ... rest of your node and edge creation logic ...

    setNodes([episodeNode, ...chapterNodes, ...pointNodes]);
    setEdges([...episodeEdges, ...pointEdges]);
    // After creating nodes and edges, apply the layout
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      [episodeNode, ...chapterNodes, ...pointNodes],
      [...episodeEdges, ...pointEdges]
    );
    if (wasLoaded) {
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
      // reset states except wasLoaded
    }
  }, [props.selectedEpisodeState, wasLoaded]);

  return (
    <div className="flex flex-col items-center justify-between m-5 w-full">
      <div className="sm:h-[50vh] w-full h-[50vh] rounded">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onInit={(reactFlowInstance) => {
            reactFlowInstance.fitView();
            setWasLoaded(true);
          }}
          nodeTypes={nodeTypes}
          fitView
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          defaultViewport={defaultViewport}
          minZoom={0.2}
          maxZoom={4}>
          <Controls position="top-left" />
          <Background />
          <DownloadButton />
        </ReactFlow>
      </div>
    </div>
  );
}

export default MindMap;
