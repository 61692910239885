import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GhostContentAPI from "@tryghost/content-api";
import { FadeLoader } from "react-spinners";
import { Container } from "../Container";
import { Header } from "../Header";
import { Footer } from "../Footer";
import SEO from "../../SEO";

const api = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_API_URL,
  key: process.env.REACT_APP_GHOST_CONTENT_API_KEY,
  version: "v5.0",
});

export function BlogPost() {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    api.posts
      .read({ slug }, { include: "authors" })
      .then((response) => {
        setPost(response);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <>
        <Header />
        <main>
          <Container className="flex justify-center items-center min-h-screen">
            <FadeLoader color="#36D7B7" />
          </Container>
        </main>
        <Footer />
      </>
    );
  }

  if (!post) {
    return (
      <>
        <Header />
        <main>
          <Container>
            <p>Blog post not found.</p>
          </Container>
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <SEO title={`${post.title} | PodScribe.IO`} description={post.excerpt} />
      <Header />
      <main>
        <Container className="py-16">
          {loading ? (
            <p>Loading blog post...</p>
          ) : !post ? (
            <p>Blog post not found.</p>
          ) : (
            <article className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
              {post.feature_image && (
                <img src={post.feature_image} alt={post.title} className="w-full h-64 object-cover mb-8" />
              )}
              <h1 className="text-4xl font-bold text-center text-primary-900 mb-4">{post.title}</h1>
              <div className="flex items-center mb-8">
                {post.primary_author && post.primary_author.profile_image && (
                  <img
                    src={post.primary_author.profile_image}
                    alt={post.primary_author.name}
                    className="w-16 h-16 rounded-full object-cover mr-4"
                  />
                )}
                <div className="text-left">
                  <p className="text-xl font-semibold text-primary-900">
                    {post.primary_author ? post.primary_author.name : "Unknown Author"}
                  </p>
                  <p className="text-gray-500">
                    {new Date(post.published_at).toLocaleDateString()} &bull; {post.reading_time} min read
                  </p>
                </div>
              </div>
              <div
                className="prose prose-lg prose-primary mx-auto"
                dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </article>
          )}
        </Container>
      </main>
      <Footer />
    </>
  );
}
