import Img from "../../UI/Img";

import { Container } from "./Container";
import backgroundImage from "../../assets/background-faqs.jpg";

const faqs = [
  [
    {
      question: "How much AI processing can I do for free?",
      answer:
        "For a limited time, you can get 100 minutes of AI processing for free. After that, you can subscribe to our paid plans to continue using our AI processing features.",
    },
    {
      question: "What happens if I run out of AI processing minutes?",
      answer:
        "If you run out of AI processing minutes, you can purchase more minutes through the app. The price is $10 per 1000 minutes.",
    },
    {
      question: "Why do you moved from episode credits to AI processing minutes?",
      answer:
        "Charges for AI processing are based on the amount of data that needs to be processed. This is why we moved from episode credits to AI processing minutes. This way, you only pay for the amount of data that needs to be processed.",
    },
  ],
  [
    {
      question: "Can I discover new podcasts and episodes via the app?",
      answer:
        "Absolutely! Our app features a 'Discover' section where you can explore new podcasts and episodes. If you find ones you love, you can add them to your inbox and request AI processing for them.",
    },
    {
      question: "Can I chat with the Podcast transcript using generative AI functionality?",
      answer: "This is a feature we are currently working on and will be available soon.",
    },
    {
      question: "Do I have access to episode transcripts, summaries, and key takeaways other users have created?",
      answer: "Yes, absolutely.",
    },
  ],
  [
    {
      question: "How accurate are the transcriptions, summaries, and key takeaways created by your service?",
      answer:
        "We strive to ensure our transcriptions are as accurate as possible across all 30+ supported languages (currently in Beta). However, keep in mind that the accuracy can slightly vary depending upon the audio quality and clarity of speech in the podcast.",
    },
    {
      question: "I have forgotten my password. How can I reset it?",
      answer: "Please send us an email and we will help you reset your password.",
    },
    {
      question: "What is the Inbox feature?",
      answer:
        "It is like in any email inbox. You can add episodes to your inbox and request AI processing for them, read the transcriptions, summaries, and key takeaways, and delete them when you are done.",
    },
  ],
];

export function Faqs() {
  return (
    <section id="faq" aria-labelledby="faq-title" className="relative overflow-hidden bg-slate-50 py-20 sm:py-32">
      <Img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 id="faq-title" className="font-display text-3xl tracking-tight text-primary-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-primary-700">
            If you can’t find what you’re looking for, please{" "}
            <a href="mailto:info@podscribe.io" className="font-medium text-primary-600 hover:text-primary-500">
              contact us
            </a>
            .
          </p>
        </div>
        <ul role="list" className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-primary-900">{faq.question}</h3>
                    <p className="mt-4 text-sm text-primary-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
