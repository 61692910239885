import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, LightningBoltIcon, ExclamationIcon } from "@heroicons/react/outline";

export default function PodscribeActionModal(props) {
  const cancelButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    title,
    BodyText,
    CloseButtonText,
    ActionButtonText,
    HandleOnClose,
    HandleOnAction,
    show,
    availableMinutes,
    requiredMinutes
  } = props;

  const hasEnoughCredits = availableMinutes >= requiredMinutes;

  const handleAction = async () => {
    if (!hasEnoughCredits) return;
    setIsLoading(true);
    try {
      await HandleOnAction();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={HandleOnClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${hasEnoughCredits ? 'bg-green-100' : 'bg-red-100'}`}>
                    {hasEnoughCredits ? (
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    ) : (
                      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-neutral-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {BodyText}
                      {!hasEnoughCredits && (
                        <p className="mt-2 text-sm text-red-600">
                          You don't have enough credits to process this episode. You need {requiredMinutes} minutes, but you only have {availableMinutes} minutes available.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 disabled:opacity-50 disabled:cursor-not-allowed ${
                      hasEnoughCredits
                        ? 'bg-primary-700 hover:bg-primary-600 focus-visible:outline-primary-700'
                        : 'bg-gray-400'
                    }`}
                    onClick={handleAction}
                    disabled={isLoading || !hasEnoughCredits}
                  >
                    {isLoading ? (
                      <>
                        <LightningBoltIcon className="animate-pulse mr-2 h-5 w-5" />
                        Processing...
                      </>
                    ) : (
                      <>
                        <LightningBoltIcon className="mr-2 h-5 w-5" />
                        {hasEnoughCredits ? ActionButtonText : 'Not Enough Credits'}
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:col-start-1 sm:mt-0"
                    onClick={() => HandleOnClose()}
                    ref={cancelButtonRef}
                    disabled={isLoading}
                  >
                    {CloseButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
