import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, ClipboardCopyIcon, DownloadIcon, LockClosedIcon } from "@heroicons/react/outline";
import { ClipboardIcon } from "@heroicons/react/solid";
import { useCopyToClipboard } from "usehooks-ts";
import { axiosPrivate } from "../../common/axiosPrivate";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import useAuth from "../../hooks/useAuth";

export default function ExportModal({ show, onClose, episode }) {
  const { auth } = useAuth();
  const isSubscribed = auth.user.subscription_status === "active";
  const [copiedText, copy] = useCopyToClipboard();
  const [isCopiedSummary, setIsCopiedSummary] = useState(false);
  const [isCopiedTranscript, setIsCopiedTranscript] = useState(false);
  const [loadingExportSummary, setLoadingExportSummary] = useState(false);
  const [loadingExportTranscript, setLoadingExportTranscript] = useState(false);

  const handleExport = async (exportType) => {
    if (!episode) {
      toast.error("No episode selected for export.");
      return;
    }

    const endpoint = `repository/export/${episode.id}`;
    const params = { export_type: exportType };

    try {
      if (exportType === "summary") {
        setLoadingExportSummary(true);
      } else if (exportType === "transcript") {
        setLoadingExportTranscript(true);
      }

      const response = await axiosPrivate.get(endpoint, { params });
      const { content, content_type } = response.data;

      // Use saveAs to download the file
      saveAs(
        new Blob([content], { type: content_type }),
        `${episode.title}_${exportType}.${exportType === "summary" ? "md" : "txt"}`
      );

      toast.success(`${exportType.charAt(0).toUpperCase() + exportType.slice(1)} exported successfully.`);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to export ${exportType}.`);
    } finally {
      if (exportType === "summary") {
        setLoadingExportSummary(false);
      } else if (exportType === "transcript") {
        setLoadingExportTranscript(false);
      }
    }
  };
  const handleCopy = async (exportType) => {
    if (!episode) {
      toast.error("No episode selected for export.");
      return;
    }

    const endpoint = `repository/export/${episode.id}`;
    const params = { export_type: exportType };

    try {
      if (exportType === "summary") {
        setIsCopiedSummary(true);
        setTimeout(() => setIsCopiedSummary(false), 2000);
      } else {
        setIsCopiedTranscript(true);
        setTimeout(() => setIsCopiedTranscript(false), 2000);
      }

      if (typeof ClipboardItem !== "undefined" && navigator.clipboard.write) {
        const clipboardItem = new ClipboardItem({
          "text/plain": new Promise(async (resolve) => {
            try {
              const response = await axiosPrivate.get(endpoint, { params });
              const { content } = response.data;
              if (!content) {
                resolve(new Blob([""]));
                return;
              }
              resolve(new Blob([content]));
            } catch (error) {
              console.error("API error:", error);
              resolve(new Blob([""])); // Fallback to empty on error
            }
          }),
        });
        await navigator.clipboard.write([clipboardItem]);
      } else {
        // Fallback for older browsers
        const response = await axiosPrivate.get(endpoint, { params });
        const { content } = response.data;
        await navigator.clipboard.writeText(content);
      }

      toast.success(`${exportType.charAt(0).toUpperCase() + exportType.slice(1)} copied to clipboard.`);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to copy ${exportType}.`);
    }
  };

  if (!episode) return null;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* Background overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* Centering trick */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          {/* Modal panel */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {/* Close button */}
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={onClose}>
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Modal Content */}
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Export Episode
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Choose how you'd like to export the contents of this episode. You can download the AI Summary or
                      the Transcript, or copy them to your clipboard for easy sharing.
                    </p>
                  </div>
                </div>
              </div>

              {/* Export Options */}
              <div className="mt-5 sm:mt-4 space-y-6">
                {/* AI Summary Export Section */}
                <div className="flex flex-col bg-gray-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-md font-semibold text-gray-700 mb-2">AI Summary</h4>
                  <p className="text-sm text-gray-600 mb-4">
                    Download or copy the AI-generated summary of the episode for quick reference.
                  </p>
                  <div className="flex space-x-3">
                    <button
                      onClick={() => isSubscribed && handleExport("summary")}
                      className={`flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                        isSubscribed ? "bg-indigo-600 hover:bg-indigo-700" : "bg-indigo-400 cursor-not-allowed"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      disabled={!isSubscribed || loadingExportSummary}>
                      {loadingExportSummary ? "Downloading..." : "Download"}
                      <DownloadIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                    </button>
                    <button
                      onClick={() => isSubscribed && handleCopy("summary")}
                      className={`flex items-center justify-center p-2 border border-gray-300 shadow-sm rounded-md text-gray-700 ${
                        isSubscribed ? "bg-white hover:bg-gray-50" : "bg-gray-100 cursor-not-allowed"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      aria-label="Copy AI Summary to Clipboard"
                      title="Copy AI Summary to Clipboard"
                      disabled={!isSubscribed}>
                      {isCopiedSummary ? (
                        <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <ClipboardCopyIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </button>
                  </div>
                </div>

                {/* Transcript Export Section */}
                <div className="flex flex-col bg-gray-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-md font-semibold text-gray-700 mb-2">Transcript</h4>
                  <p className="text-sm text-gray-600 mb-4">
                    Download or copy the full transcript of the episode for detailed review.
                  </p>
                  <div className="flex space-x-3">
                    <button
                      onClick={() => isSubscribed && handleExport("transcript")}
                      className={`flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                        isSubscribed ? "bg-green-600 hover:bg-green-700" : "bg-green-400 cursor-not-allowed"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                      disabled={!isSubscribed || loadingExportTranscript}>
                      {loadingExportTranscript ? "Downloading..." : "Download"}
                      <DownloadIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                    </button>
                    <button
                      onClick={() => isSubscribed && handleCopy("transcript")}
                      className={`flex items-center justify-center p-2 border border-gray-300 shadow-sm rounded-md text-gray-700 ${
                        isSubscribed ? "bg-white hover:bg-gray-50" : "bg-gray-100 cursor-not-allowed"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                      aria-label="Copy Transcript to Clipboard"
                      title="Copy Transcript to Clipboard"
                      disabled={!isSubscribed}>
                      {isCopiedTranscript ? (
                        <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <ClipboardCopyIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </button>
                  </div>
                </div>

                {/* Subscription Required Message */}
                {!isSubscribed && (
                  <div className="flex flex-col items-center bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg mt-4">
                    <LockClosedIcon className="h-8 w-8 text-yellow-400 mb-2" aria-hidden="true" />
                    <h4 className="text-md font-semibold text-yellow-800 mb-2">Subscription Required</h4>
                    <p className="text-sm text-yellow-700 text-center mb-4">
                      To export episode summaries and transcripts, you need an active subscription. Subscribe now to
                      unlock this feature and many more!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ExportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  episode: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
  }),
};
