import { XIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { Children, useState, useEffect } from "react";
import ReactDOM from "react-dom";

export function BannerWithLinks(props) {
  const [isVisible, setIsVisible] = useState(true);
  const [domReady, setDomReady] = useState(false);
  const link = props.link;
  const strongText = props.strongText;

  useEffect(() => {
    setDomReady(true);
  }, []);

  if (!isVisible) {
    return null;
  }
  return domReady
    ? ReactDOM.createPortal(
        <div className="flex items-center gap-x-6 bg-gradient-to-r from-secondary-600 to-primary-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="text-sm leading-6 text-white">
            <Link to={link}>
              <strong className="font-semibold">{strongText}</strong>
              <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              {props.children}
            </Link>
          </p>
          <div className="flex flex-1 justify-end">
            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5 text-white" aria-hidden="true" onClick={() => setIsVisible(false)} />
            </button>
          </div>
        </div>,
        document.getElementById("banner")
      )
    : null;
}

export function Banner(props) {
  const [isVisible, setIsVisible] = useState(true);
  const strongText = props.strongText;
  const [domReady, setDomReady] = useState(false);
  useEffect(() => {
    setDomReady(true);
  }, []);
  if (!isVisible) {
    return null;
  }

  return domReady
    ? ReactDOM.createPortal(
        <div className="flex items-center gap-x-6 bg-gradient-to-r from-secondary-600 to-primary-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <p className="text-xs sm:text-sm leading-6 text-white">
            <strong className="font-bold">{strongText}</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            {props.children}
          </p>
          <div className="flex flex-1 justify-end">
            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5 text-white" aria-hidden="true" onClick={() => setIsVisible(false)} />
            </button>
          </div>
        </div>,
        document.getElementById("banner")
      )
    : null;
}
