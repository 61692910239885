import React, { useState } from "react";
import { HeartIcon } from "@heroicons/react/solid";
import {
  HeartIcon as HeartIconOutlined,
  ExternalLinkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Img from "./Img";

export default function PodcastProfileHeadingCard({ feed, handleFavorite }) {
  const [isFavorite, setIsFavorite] = useState(feed.is_favorite);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
    handleFavorite(feed.id, !isFavorite);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="bg-white w-full mb-8 relative overflow-hidden heropattern-diagonal-primary-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-10">
        <div className="flex flex-col md:flex-row items-center md:items-start">
          <Img
            src={feed.image}
            alt={feed.title}
            className="h-48 w-48 rounded-lg shadow-md mb-6 md:mb-0 md:mr-8 object-cover"
          />
          <div className="flex-grow text-center md:text-left">
            <div className="flex flex-col md:flex-row justify-between items-center md:items-start mb-4">
              <div>
                <Link to={feed.link} target="_blank" className="group inline-flex items-center">
                  <h1 className="text-3xl font-bold text-primary-800 group-hover:text-secondary-800 transition-colors duration-200">
                    {feed.title}
                  </h1>
                  <ExternalLinkIcon
                    className="ml-2 h-6 w-6 text-primary-600 group-hover:text-secondary-600"
                    aria-hidden="true"
                  />
                </Link>
                <h2 className="text-xl font-medium mt-2 text-primary-700">
                  by <span className="text-secondary-700">{feed.author}</span>
                </h2>
              </div>
              <button
                onClick={toggleFavorite}
                className={`mt-4 md:mt-0 transition-all duration-200 p-3 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ${
                  isFavorite ? "bg-red-100 hover:bg-red-200" : "bg-primary-100 hover:bg-primary-200"
                }`}
                aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}>
                {isFavorite ? (
                  <HeartIcon className="h-8 w-8 text-red-600" aria-hidden="true" />
                ) : (
                  <HeartIconOutlined className="h-8 w-8 text-primary-600" aria-hidden="true" />
                )}
              </button>
            </div>
            <div className="flex flex-wrap justify-center md:justify-start gap-2 mb-4">
              {feed.categories &&
                Object.values(feed.categories).map((category, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center rounded-full bg-primary-50 px-3 py-1 text-sm font-medium text-primary-700 ring-1 ring-inset ring-primary-700/10">
                    {category}
                  </span>
                ))}
            </div>
            <p className={`text-base text-neutral-700 ${isExpanded ? "" : "line-clamp-3"}`}>{feed.description}</p>
            <button
              onClick={toggleExpand}
              className="mt-2 inline-flex items-center text-sm font-medium text-primary-600 hover:text-secondary-600">
              {isExpanded ? (
                <>
                  Show less
                  <ChevronUpIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                <>
                  Read more
                  <ChevronDownIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
