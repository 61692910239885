import { ChevronDownIcon, SearchIcon, SortAscendingIcon } from "@heroicons/react/solid";
import { useState, useEffect, useRef } from "react";
import CardHeadingTabs from "./CardHeadingTabs";
import { XCircleIcon } from "@heroicons/react/outline";
import SearchInput from "./SearchComponent";

export default function CardHeadingSearch(props) {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const timeOutId = setTimeout(() => props.onSearch(searchTerm), 500);
    return () => clearTimeout(timeOutId);
  }, [searchTerm]);

  return (
    <div className="flex flex-col sm:flex-row pb-2 mb-4 border-b border-neutral-200">
      <div className="w-full sm:flex-1">
        <CardHeadingTabs onSelect={props.onSelect} />
      </div>
      {props.showSearchBar && (
        <div className="mt-3 sm:mt-0 flex justify-center sm:justify-start sm:flex-shrink-0 sm:ml-4">
          <SearchInput value={searchTerm} onChange={onSearch} placeholder="Search Transcript..." className="w-52" />
        </div>
      )}
    </div>
  );
}
