import { Fragment, useState, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import * as Icons from "@heroicons/react/outline";

export default function AddFolderModal({ show, onClose, onSave }) {
  const [folderName, setFolderName] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("FolderIcon");
  const [showAllIcons, setShowAllIcons] = useState(false);

  const commonFolderIcons = useMemo(
    () => [
      "FolderIcon",
      "FolderOpenIcon",
      "BookmarkIcon",
      "StarIcon",
      "HeartIcon",
      "MusicNoteIcon",
      "DocumentIcon",
      "DocumentTextIcon",
      "CollectionIcon",
      "ArchiveIcon",
      "BookOpenIcon",
      "TagIcon",
      "LightBulbIcon",
      "FireIcon",
      "SparklesIcon",
      "InboxIcon",
    ],
    []
  );

  const iconList = useMemo(() => {
    const allIcons = Object.keys(Icons)
      .filter((key) => key.endsWith("Icon"))
      .map((key) => ({
        name: key,
        icon: Icons[key],
      }));

    // Sort so common icons appear first
    return allIcons.sort((a, b) => {
      const aIsCommon = commonFolderIcons.includes(a.name);
      const bIsCommon = commonFolderIcons.includes(b.name);
      if (aIsCommon && !bIsCommon) return -1;
      if (!aIsCommon && bIsCommon) return 1;
      return a.name.localeCompare(b.name);
    });
  }, [commonFolderIcons]);

  const displayedIcons = showAllIcons ? iconList : iconList.slice(0, 16);

  const handleSave = () => {
    onSave({
      name: folderName,
      icon: selectedIcon,
    });
    onClose();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl w-full max-w-md">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button onClick={onClose} className="rounded-md text-gray-400 hover:text-gray-500">
                  <XIcon className="h-6 w-6" />
                </button>
              </div>

              <div className="mt-3">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Create New Folder
                </Dialog.Title>

                <div className="mt-4">
                  <input
                    type="text"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    placeholder="Folder name"
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">Select Icon</label>
                  <div className="mt-2 grid grid-cols-8 gap-2">
                    {displayedIcons.map(({ name, icon: Icon }) => (
                      <button
                        key={name}
                        onClick={() => setSelectedIcon(name)}
                        className={`p-2 rounded-md ${
                          selectedIcon === name
                            ? "bg-primary-100 text-primary-600"
                            : "text-gray-400 hover:text-gray-500"
                        }`}>
                        <Icon className="h-5 w-5" />
                      </button>
                    ))}
                  </div>
                  {iconList.length > 16 && (
                    <button
                      onClick={() => setShowAllIcons(!showAllIcons)}
                      className="mt-2 text-sm text-primary-600 hover:text-primary-700">
                      {showAllIcons ? "Show Less" : "Load More Icons"}
                    </button>
                  )}
                </div>

                <div className="mt-5">
                  <button
                    onClick={handleSave}
                    disabled={!folderName}
                    className="w-full px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 disabled:bg-gray-300">
                    Create Folder
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
