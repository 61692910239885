import React from "react";
import { Link } from "react-router-dom";

const RegisterCallToActionBottom = () => {
  return (
    <div className="bg-primary-50 p-4 mt-8 rounded-lg shadow-sm">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <h3 className="text-sm font-medium text-primary-800">Still considering?</h3>
          <p className="mt-1 text-sm text-primary-600">Don't miss out on full access to episode notes and insights.</p>
        </div>
        <div className="ml-4">
          <Link
            to="/register"
            className="inline-flex items-center px-3 py-2 border border-primary-300 shadow-sm text-sm leading-4 font-medium rounded-md text-primary-700 bg-white hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Register Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterCallToActionBottom;
